import React, {Component} from 'react';
import classes from './Increase.module.css';
import Buttons from "../ButtonGroup/Buttons";
import interactive from '../../../assets/images/icons/interactivity.png';
import script from '../../../assets/images/icons/SCRIPT.png';
import numberOne from '../../../assets/images/icons/numberone.png'
import Divider from "../Divider/Divider";
import Leo from "../Leo/Leo";

class Increase extends Component {

    render() {
        return (
            <div className={classes.mainContainer}>

                <div className={classes.root}>

                    <div className={classes.container}>

                        <div className={classes.iconSection}>
                            <div className={classes.headerContainer}>
                                <h1 className={classes.increaseHeader}>
                                    WHAT MAKES IT SO EFFECTIVE
                                </h1>
                            </div>
                            <div className={classes.left}>

                                <div className={classes.iconContainer}>
                                    <p className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <img src={numberOne} width={108} height={102} alt="interactive"/>
                                            <h1 className={classes.headingText}>
                                                LIVE CALLS WITH JORDAN BELFORT
                                            </h1>
                                        </div>
                                        What’s better than getting advice straight from the mastermind who started it
                                        all? You’ll be invited to exclusive monthly virtual strategy sessions with
                                        Jordan that typically range from $10,000 to $25,000 an hour, where you can seek
                                        tailored advice and guidance on your career journey. Additionally, you’ll have
                                        an entire community of people to support you whenever you need.
                                    </p>
                                    <p className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <img src={script} width={95} height={95} alt="interactive"/>
                                            <h1 className={classes.headingText}>
                                                VALUABLE RESOURCES
                                            </h1>
                                        </div>
                                        We’re providing you a toolbox of resources that will help you build your wealth.
                                        This 12-month mentorship program will give you all the resources you need to
                                        succeed in your field. We’re also including an extended version of Jordan’s
                                        famous Script Builder course as well as the Stratton Oakmont Rebuttals Guide
                                        amongst other tools that will act as a framework for all your future success and
                                        help you reach your goals.
                                    </p>
                                </div>
                                <div className={classes.iconContainer}>
                                    <p className={classes.lastList}>
                                        <div className={classes.headingRow}>
                                            <img src={interactive} width={95} height={95} alt="interactive"/>
                                            <h1 className={classes.headingText}>
                                                INTERACTIVE MODULES
                                            </h1>
                                        </div>
                                        Our interactive modules add more value than traditional online or even in-person
                                        classes. Studies show that your retention rate decreases over time without
                                        engagement. That’s why our interactive training platform has a highly advanced
                                        learning algorithm that allows you to answer Jordan’s questions in real-time.
                                        Based on your answers, the algorithm will adjust your training to fit your
                                        specific needs.
                                    </p>
                                    <div className={classes.videoRightBox}>
                                        <Leo/>
                                    </div>
                                </div>

                                <Buttons/>

                            </div>
                            <div className={classes.divider}>
                                <Divider
                                    /*
                                                            titleStart=""
                                    */
                                    spanOne="TRY IT RISK-FREE"
                                    /*
                                                            titleEnd="OF OUR PRODUCTS"
                                    */
                                    subTitle="Unconditional 30-day Money Back Guarantee"
                                    /*            quotes='No Risk... No Downside... Unlimited Upside...'*/


                                />
                            </div>


                        </div>

                    </div>

                </div>


                <div className={classes.mobileRoot}>
                    <div className={classes.headingRow}>
                        <div className={classes.iconRow}>

                            <img src={interactive} width={108} height={102} alt="interactive"/>

                            <h1 className={classes.headingText}>
                                INTERACTIVE MODULES
                            </h1>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                Our interactive modules add more value than traditional online or even in-person
                                classes. Studies show that your retention rate decreases over time without engagement.
                                That’s why our interactive training platform has a highly advanced learning algorithm
                                that allows you to answer Jordan’s questions in real-time. Based on your answers, the
                                algorithm will adjust your training to fit your specific needs.
                            </p>
                        </div>

                        <div className={classes.iconRow}>
                            <img src={script} width={95} height={95} alt="interactive"/>

                            <h1 className={classes.headingText}>
                                VALUABLE RESOURCES
                            </h1>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                We’re providing you a toolbox of resources that will help you build your wealth. This
                                12-month mentorship program will give you all the resources you need to succeed in your
                                field. We’re also including an extended version of Jordan’s famous Script Builder course
                                as well as the Stratton Oakmont Rebuttals Guide amongst other tools that will act as a
                                framework for all your future success and help you reach your goals.
                            </p>
                        </div>

                        <div className={classes.iconRow}>
                            <img src={numberOne} width={108} height={102} alt="interactive"/>

                            <h1 className={classes.headingText}>
                                EXCLUSIVE REAL-TIME CALLS WITH JORDAN BELFORT </h1>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                What’s better than getting advice straight from the mastermind who started it all? If
                                you join our mentorship program, you’ll be invited to exclusive monthly virtual strategy
                                sessions with Jordan that typically range from $10,000 to $25,000 an hour, where you can
                                seek tailored advice and guidance on your career journey. Additionally, you’ll have an
                                entire community of people to support you whenever you need.
                            </p>
                        </div>

                        <div className={classes.newRow}>
                            <Leo/>
                        </div>
                        <Buttons/>


                    </div>


                </div>
                <div className={classes.mobileDivider}>
                    <Divider
                        /*
                                                titleStart=""
                        */
                        spanOne="TRY IT RISK-FREE"
                        /*
                                                titleEnd="OF OUR PRODUCTS"
                        */
                        subTitle="Unconditional 30-day Money Back Guarantee"
                        /*
                                                quotes='No Risk... No Downside... Unlimited Upside...'
                        */


                    />
                </div>
            </div>


        )

    }
}

export default Increase;