import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    legacyData: null,
    loading: false,
    legacyLoading: false,
    error: null,
    legacyError: null,
    spendData: null,
    spendLoading: false,
    spendError: null
};

const analyticsStart = ( state, action ) => {
    return updateObject( state, {
        loading: true,
        error: null
    } );
};

const analyticsSuccess = ( state, action ) => {
    return updateObject( state, {
        data: action.data,
        loading: false,
        error: null
    } );
};

const analyticsFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const spendStart = ( state, action ) => {
    return updateObject( state, {
        spendLoading: true,
        spendError: null
    } );
};

const spendSuccess = ( state, action ) => {
    return updateObject( state, {
        spendData: action.data,
        spendLoading: false,
        spendError: null
    } );
};

const spendFail = ( state, action ) => {
    return updateObject( state, {
        spendLoading: false,
        spendError: action.error
    } );
};

const legacyAnalyticsStart = ( state, action ) => {
    return updateObject( state, {
        legacyLoading: true,
        legacyError: null
    } );
};

const legacyAnalyticsSuccess = ( state, action ) => {
    return updateObject( state, {
        legacyData: action.data,
        legacyLoading: false,
        legacyError: null
    } );
};

const legacyAnalyticsFail = ( state, action ) => {
    return updateObject( state, {
        legacyLoading: false,
        legacyError: action.error
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ANALYTICS_START: return analyticsStart(state, action);
        case actionTypes.GET_ANALYTICS_SUCCESS: return analyticsSuccess(state, action);
        case actionTypes.GET_ANALYTICS_FAIL: return analyticsFail(state, action);
        case actionTypes.GET_LEGACY_ANALYTICS_START: return legacyAnalyticsStart(state, action);
        case actionTypes.GET_LEGACY_ANALYTICS_SUCCESS: return legacyAnalyticsSuccess(state, action);
        case actionTypes.GET_LEGACY_ANALYTICS_FAIL: return legacyAnalyticsFail(state, action);
        case actionTypes.GET_SPEND_START: return spendStart(state, action);
        case actionTypes.GET_SPEND_SUCCESS: return spendSuccess(state, action);
        case actionTypes.GET_SPEND_FAIL: return spendFail(state, action);
        default:
            return state;
    }
};

export default reducer;