import * as actionTypes from "./actionTypes";
import {getData, postData, postDataMetabase} from "../../shared/utility";
import {isMobile} from 'react-device-detect';
import axios from "axios";
import uuidv4 from "uuid/v4";

export const getAnalyticsStart = () => {
    return {
        type: actionTypes.GET_ANALYTICS_START
    }
};

export const getAnalyticsSuccess = (data) => {
    return {
        type: actionTypes.GET_ANALYTICS_SUCCESS,
        data: data
    }
};

export const getAnalyticsFail = (error) => {
    return {
        type: actionTypes.GET_ANALYTICS_FAIL,
        error: error
    }
};

export const getLegacyAnalyticsStart = () => {
    return {
        type: actionTypes.GET_LEGACY_ANALYTICS_START
    }
};

export const getLegacyAnalyticsSuccess = (data) => {
    return {
        type: actionTypes.GET_LEGACY_ANALYTICS_SUCCESS,
        data: data
    }
};

export const getLegacyAnalyticsFail = (error) => {
    return {
        type: actionTypes.GET_LEGACY_ANALYTICS_FAIL,
        error: error
    }
};

export const getFbSpendStart = () => {
    return {
        type: actionTypes.GET_SPEND_START
    }
};

export const getFbSpendSuccess = (data) => {
    return {
        type: actionTypes.GET_SPEND_SUCCESS,
        data: data
    }
};

export const getFbSpendFail = (error) => {
    return {
        type: actionTypes.GET_SPEND_FAIL,
        error: error
    }
};

export const sendAnalytics = (eventTag) => {
    return dispatch => {
        let userId = localStorage.getItem("userId");
        if (!userId) {
            userId = "";
        }
        let email = localStorage.getItem("email");
        if (!email) {
            email = "";
        }
        let source = localStorage.getItem("source");
        if (!source) {
            source = ""
        }
        const platform = isMobile ? "mobile" : "desktop";
        let ip = localStorage.getItem("ip");
        let sessionId = localStorage.getItem("jbSessionId");
        if (sessionId === null) {
            sessionId = uuidv4();
            localStorage.setItem("jbSessionId", sessionId);
        }
        const requestData = {
            email:  email,
            userId: userId,
            platform: platform,
            source: source,
            eventTag: eventTag,
            ip: ip,
            referrer: document.referrer,
            sessionId: sessionId
        };

        if (ip === null) {
            axios.get("https://ipapi.co/json/").then(result => {
                localStorage.setItem("ip", result.data["ip"]);
                localStorage.setItem("country", result.data["country"]);
                ip = result.data["ip"];
                requestData["ip"] = ip;
                if (ip === "47.144.159.90") {
                    window.mixpanel.opt_out_tracking(); //exclude mixpanel tracking for our office
                }
                sendAnalyticsToBackend(requestData, eventTag);
                sendAnalyticsToMetabase(requestData, eventTag);
            }).catch(err => {
                console.warn("Could not load ip");
                ip = "";
                requestData["ip"] = ip;
                sendAnalyticsToBackend(requestData, eventTag);
                sendAnalyticsToMetabase(requestData, eventTag);
            })
        } else {
            sendAnalyticsToBackend(requestData, eventTag);
            sendAnalyticsToMetabase(requestData, eventTag);
        }

    };
};

const sendAnalyticsToBackend = (requestData, eventTag) => {
    postData(
        "/analytics/event",
        requestData,
        response => {
            console.log("event sent", eventTag)
        },
        err => {
            console.warn("sending analytics failed")
        });
};
const sendAnalyticsToMetabase = (requestData, eventTag) => {
    postDataMetabase(
        "/analytics/event",
        requestData,
        response => {
            console.log("event sent", eventTag)
        },
        err => {
            console.warn("sending analytics failed")
        });
};


export const getFbSpend = (start, end, password) => {
    return dispatch => {
        dispatch(getFbSpendStart());

        const startDateTime = start.year + "-" + start.month + "-" + start.day + "-" + start.hour + ":" + start.minute;
        const endDateTime = end.year + "-" + end.month + "-" + end.day + "-" + end.hour + ":" + end.minute;
        getData(
            "/analytics/spend?start=" + startDateTime + "&end=" + endDateTime + "&pw=" + password,
            response => {
                dispatch(getFbSpendSuccess(response.data));
            },
            err => {
                console.warn(err);
                dispatch(getFbSpendFail(err.response.data.message));
            });
    };
};


export const getAnalytics = (start, end, password) => {
    return dispatch => {
        dispatch(getAnalyticsStart());

        const startDateTime = start.year + "-" + start.month + "-" + start.day + "-" + start.hour + ":" + start.minute;
        const endDateTime = end.year + "-" + end.month + "-" + end.day + "-" + end.hour + ":" + end.minute;
        getData(
            "/analytics?start=" + startDateTime + "&end=" + endDateTime + "&pw=" + password,
            response => {
                dispatch(getAnalyticsSuccess(response.data));
            },
            err => {
                console.warn(err);
                dispatch(getAnalyticsFail(err.response.data.message));
            });
    };
};

export const getLegacyAnalytics = (password) => {
    return dispatch => {
        dispatch(getLegacyAnalyticsStart());

        getData(
            "/analytics/legacy?pw=" + password,
            response => {
                dispatch(getLegacyAnalyticsSuccess(response.data));
            },
            err => {
                dispatch(getLegacyAnalyticsFail(err.response.data.message));
            });
    };
};