import React, {Component} from 'react';
import classes from "./Upsell.module.css";
import {IoIosCheckmarkCircleOutline} from "react-icons/io";
import * as productTags from '../../shared/products';
import {SCRIPT_BUILDER_DOWNSELL} from '../../shared/products';
import PageSpinner from "../Spinner/PageSpinner";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";
import {DOWNSELL, DOWNSELL_PAGE, SKIP_DOWNSELL} from "../../shared/events";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import VimeoPlayer from "../Vimeo/VimeoPlayer";
import downsellBanner from "../../assets/images/downsell-banner.jpg";
import _ from "lodash";

class Downsell extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sendEvent(DOWNSELL_PAGE);
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        })
    }

    skip() {
        this.props.sendEvent(SKIP_DOWNSELL);
        this.props.skipScriptBuilder();
    }

    submit() {
        this.props.sendEvent(DOWNSELL);

        if (this.state.brainTreeInstance) {
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    // Do something with the error
                } else {
                    this.props.setNonceAndBuy(payload.nonce, [SCRIPT_BUILDER_DOWNSELL], this.props.transactionProperties)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    render() {
        return (
            <div className={classes.root}>
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                <h2 className={classes.subtitle}>Bingo! - You Just Hit the Jackpot!</h2>
                <h1 className={classes.subtitle}>You're One of the Select Few Who Qualifies for a Level II
                    Script-Builder
                    Scholarship!</h1>
                <div className={classes.infoBlock}>
                    <div className={classes.listBox}>
                        <div className={classes.bannerBox}>
                            <img className={classes.banner} src={downsellBanner} alt="Save 400 dollars"/>
                        </div>
                        <h2 className={classes.videoTitle}>Dramatically Increase Your Closing Rate Today</h2>
                        <div className={classes.videoWrapper}>
                            <VimeoPlayer
                                width={775}
                                height={436}
                                threshold={1100}
                                multiplier={0.85}
                                playing
                                url="https://vimeo.com/user17087177/sb100off"
                            />
                        </div>
                        <div className={classes.buttonRow}>
                            <div className={classes.buttonWrapper} onClick={() => this.skip()}>
                                <button className={classes.noButton}>
                                    <b>No</b>, thank you.
                                </button>
                            </div>
                            <div className={classes.buttonWrapper}>
                                <button className={classes.yesButton} disabled={!this.state.brainTreeReady}
                                        onClick={_.debounce(() => this.submit(), 300, {
                                            'leading': true,
                                            'trailing': false
                                        })}>
                                    <b>Yes</b>, add to my purchase
                                </button>
                            </div>
                        </div>
                        <p className={classes.listTitle}>Sales cuts to all aspects of life, both business and
                            personal.
                            Think about it...</p>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                <IoIosCheckmarkCircleOutline className={classes.check}/>
                                <p>
                                    You could be a parent trying to convince your child to make their bed... or do their
                                    homework
                                </p>
                            </li>
                            <li className={classes.listItem}>
                                <IoIosCheckmarkCircleOutline className={classes.check}/>
                                <p>
                                    You could be an employee trying to persuade your boss to give you a raise or put you
                                    in charge of a certain project
                                </p>
                            </li>
                            <li className={classes.listItem}>
                                <IoIosCheckmarkCircleOutline className={classes.check}/>
                                <p>
                                    You could be out on a date, trying to convince the other person that you're someone
                                    worth seeing again
                                </p>
                            </li>
                        </ul>
                        <div className={classes.brainTreeWrapper}>
                            <CreditForm
                                brainTreeInstance={this.state.brainTreeInstance}
                                setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                                price={99}
                                product="Script Builder"
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.footerPlaceholder}/>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.braintree.loading,
        error: state.braintree.error,
        transactionProperties: state.mixPanel.superProps
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skipScriptBuilder: () => dispatch(actions.addProductSkip(productTags.SCRIPT_BUILDER_DOWNSELL)),
        sendEvent: (eventTag) => {dispatch(actions.sendAnalytics(eventTag)); dispatch(actions.sendEvent(eventTag))},
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Downsell);