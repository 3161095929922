import React, {Component} from 'react';
import classes from './ContactFormInquiry.module.css';
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Spinner from "../Spinner/Spinner";
import {CHECKOUT_PAGE, SUBMIT_INQUIRY} from "../../shared/events";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import _ from "lodash";
import ReactPixel from "react-facebook-pixel";

class ContactFormInquiry extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        phoneError: null
    };

    componentDidMount() {
        this.props.sendEvent(CHECKOUT_PAGE);
    }

    handleChangeFirstName(event) {
        let val = event.target.value;
        const error = ContactFormInquiry.validateFirstName(val);
        this.setState({
            ...this.state,
            firstName: val,
            firstNameError: error
        })
    }

    handleChangeLastName(event) {
        let val = event.target.value;
        const error = ContactFormInquiry.validateLastName(val);
        this.setState({
            ...this.state,
            lastName: val,
            lastNameError: error
        })
    }

    handleChangeEmail(event) {
        let val = event.target.value;
        const error = ContactFormInquiry.validateEmail(val);
        this.setState({
            ...this.state,
            email: val,
            emailError: error
        })
    }

    static validateFirstName(val) {
        if (val.length === 0) {
            return 'First name is required'
        }
        return null
    }

    static validateLastName(val) {
        if (val.length === 0) {
            return 'Last name is required'
        }
        return null
    }

    static validateEmail(val) {
        if (val.length <= 0) {
            return 'email is required'
        }
        return null
    }

    static validatePhone(val) {
        return null;
    }

    submit() {
        const firstNameError = ContactFormInquiry.validateFirstName(this.state.firstName);
        const lastNameError = ContactFormInquiry.validateLastName(this.state.lastName);
        const emailError = ContactFormInquiry.validateEmail(this.state.email);
        const phoneError = ContactFormInquiry.validatePhone(this.state.phone);
        if (lastNameError != null || firstNameError != null || emailError != null || phoneError != null) {
            this.setState({
                ...this.state,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                emailError: emailError,
                phoneError: phoneError
            });
            return;
        }
        const allowExists = this.props.allowExists === true;
        this.props.setContact({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            source: "Certification Funnel (Inquiry)",
            allowExists
        });
        this.props.sendEvent(SUBMIT_INQUIRY);
        window.fbq('trackCustom', 'Learn More Inquiry');
        ReactPixel.trackCustom('RequestedCall');
        window.sendOptin();

    }

    render() {
        if (this.props.loading) {
            return <div className={classes.root}>
                <div className={classes.spinnerBox}>
                    <Spinner/>
                </div>
            </div>
        }
        let country = localStorage.getItem("country");
        if (country === null) {
            country = "ZZ";
        }
        return (
            <form className={classes.root}>
                {this.props.error ? <p className={classes.formError}>{this.props.error}</p> : null}
                <input className={classes.input + (this.state.firstNameError ? ' ' + classes.inputError : '')}
                       type='text' value={this.state.firstName}
                       onChange={(event) => this.handleChangeFirstName(event)}
                       placeholder='First Name...'/>
                {this.state.firstNameError ? <p className={classes.error}>{this.state.firstNameError}</p> : null}
                <input className={classes.input + (this.state.lastNameError ? ' ' + classes.inputError : '')}
                       type='text' value={this.state.lastName}
                       onChange={(event) => this.handleChangeLastName(event)}
                       placeholder='Last Name...'/>
                {this.state.lastNameError ? <p className={classes.error}>{this.state.lastNameError}</p> : null}
                <input className={classes.input + (this.state.emailError ? ' ' + classes.inputError : '')}
                       type='email'
                       onChange={(event) => this.handleChangeEmail(event)}
                       placeholder='Email Address...'/>
                {this.state.emailError ? <p className={classes.error}>{this.state.emailError}</p> : null}
                <PhoneInput
                    placeholder="Phone Number..."
                    className={classes.phoneInput}
                    value={ this.state.phone }
                    country={ country }
                    onChange={ phone => this.setState({ phone }) } />
                {this.state.phoneError ? <p className={classes.error}>{this.state.phoneError}</p> : null}
                <button className={classes.submit + " " + classes.desktop} onClick={_.debounce(() => this.submit(), 300, {
                    'leading': true,
                    'trailing': false
                })}>Submit
                </button>
                <button className={classes.submit + " " + classes.phone} onClick={_.debounce(() => this.submit(), 300, {
                    'leading': true,
                    'trailing': false
                })}>Submit
                </button>


            </form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.contact.loading,
        error: state.contact.error,
        source: state.contact.source
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContact: (contact) => dispatch(actions.setContactInfoInquiry(contact)),
        sendEvent: (eventTag) => {dispatch(actions.sendAnalytics(eventTag)); dispatch(actions.sendEvent(eventTag))}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormInquiry);