import React, {Component} from 'react';
import classes from "../Landing.module.css";
import Buttons from "../ButtonGroup/Buttons";
import TestimonialQuote from "../TestimonialQuote/TestimonialQuote";
import Divider from "../Divider/Divider";
import Header from "../../Header/Header";
import LightboxExample from "./Box";
import 'reactjs-popup/dist/index.css';

class AboveFold extends Component {
    state = {
        popupOpen: false
    };

    handlePopup() {
        console.log("current ", this.state.popupOpen)
        if (this.state.popupOpen) {
            this.setState({
                ...this.state,
                popupOpen: false
            })
        } else {
            this.setState({
                ...this.state,
                popupOpen: true
            })
        }
        console.log("new ", this.state.popupOpen)

    }

    render() {

        return (
            <div className={classes.vidOverlay}>



                <Header height={280}/>

                <div className={classes.mainBg}>
                    <div className={classes.videoBackground}>


                        <div className={classes.titleText}>
                            Everything you ever needed to know about being a <b>world-class closer
                            and top producer </b> in your field is right here.
                        </div>

                        <div className={classes.playVideoRow}>
                            <div className={classes.playVid}>
                                PLAY
                            </div>
                            <div className={classes.playButton}>
                                <LightboxExample/>

                            </div>
                            <div className={classes.mobilePlay}>
                                <LightboxExample/>

                            </div>
                            <div className={classes.playVid}> VIDEO</div>

                        </div>
                        <div className={classes.goldDivider}/>
                        <div className={classes.learnMore}>
                            {/*   Add a zero onto your income by
                            <span className={classes.yellow}> dramatically increasing yourclosing rate</span>
                            with this revolutionary, diploma-based sales training system.*/}
                            Receive personal mentorship the #1 sales trainer in the world, Jordan Belfort ー The Wolf of
                            Wall Street. This diploma-based sales training system will give you the resources and tools
                            you need to add a zero onto your income.
                        </div>


                        <div>
                            <h1 className={classes.headerMid}>THIS IS A ONCE-IN-A-LIFETIME OPPORTUNITY</h1>
                            <h2 className={classes.subHeaderMid}>LIMITED SPOTS AVAILABLE</h2>
                        </div>


                        <div className={classes.buttonCtaRow}>


                            <Buttons

                            />
                        </div>
                        <div className={classes.goldDivider}/>

                    </div>
                    <TestimonialQuote/>

                </div>
                <div className={classes.divider}>
                    <Divider
                        titleStart="SALES TRAINING IS FOR "
                        spanOne="EVERYONE"
                        /*
                                                titleEnd="DO FOR YOU?"
                        */
                        subTitle="We’re all salespeople. Whether you’re selling a product, service, or promoting yourself."
                        /*    quotes='HOW WE’LL BRING YOU GUARANTEED SUCCESS'*/

                    />
                </div>
            </div>
        );
    }
}

export default AboveFold;