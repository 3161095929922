import axios from "axios";
import Amplitude from "amplitude-js";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const appendLeadPixel = () => {
    if (localStorage.getItem("maId") === null) {
        return
    }
    const div = document.createElement('div');
    div.innerHTML = "<iframe src=\"https://www.manydest.com/?nid=238&event_id=868&oid=841\" scrolling=\"no\" frameBorder=\"0\" width=\"1\" height=\"1\" style='display:none'/>";
    document.body.append(div);
};

export const appendConversionPixel = (email) => {
    if (localStorage.getItem("maId") === null) {
        return
    }
    const div = document.createElement('div');
    div.innerHTML = "<iframe src=\"https://www.manydest.com/?nid=238&oid=841&adv1=" + email + "\" scrolling=\"no\" frameBorder=\"0\" width=\"1\" height=\"1\" style='display:none'/>";
    document.body.append(div);
};

export const getData = (subUrl, onSuccess, onFail) => {
    let url = 'https://wolfcorpbackend.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = "http://localhost:5000" + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.get(url, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};

export const putData = (subUrl, data, onSuccess, onFail) => {
    let url = 'https://wolfcorpbackend.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url =  "http://localhost:5000" + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.put(url, data, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};

export const postData = (subUrl, data, onSuccess, onFail) => {
    let url = 'https://wolfcorpbackend.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url =  "http://localhost:5000" + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.post(url, data, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};

export const postDataMetabase = (subUrl, data, onSuccess, onFail) => {
    let url = 'https://jbstatsbackend.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5002' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.post(url, data, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};

export const postContactToBackend = (subUrl, data, onSuccess, onFail) => {
    let url = 'https://thewolfhub.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5001' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.post(url, data, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};
export const postDataHubspot= ( data, onSuccess, onFail) => {
    let url = `https://api.hubapi.com/contacts/v1/contact/createOrUpdate/email/${data.email}/?hapikey=40e65a10-1d8d-4759-a6bf-7759fb7c8740`;

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    };

    let bodyData = {
        "properties": [
            {
                "property": "name",
                "value": data.name
            },
            {
                "property": "email",
                "value": data.email
            },
            {
                "property": "phone",
                "value": data.phone
            },
            {
                "property": "industry",
                "value": data.industry
            },
            {
                "property": "level_of_experience",
                "value": data.levelOfExperience
            },
            {
                "property": "income",
                "value": data.currentMonthlyIncome
            },
            {
                "property": "desired_income",
                "value": data.desiredMonthlyIncome
            },
        ]
    }

    axios.post(url, bodyData, config)
        .then(response => {
            onSuccess(response)
        })
        .catch(err => {
            onFail(err)
        });
};

export const sendAnalytics = (tag) => {
    if (process.env.NODE_ENV === 'production') {
        window.mixpanel.track(tag);
        Amplitude.getInstance().logEvent(tag);
    } else {
        console.log("\"Sending\" analytics", tag)
    }
};

export const postMailRequest = (subUrl, data, onSuccess, onFail) => {
    let url = 'https://wolfcorpbackend.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url =  "http://localhost:5000" + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    axios.post(url, data, config);
};



export const getBaseReferrer = () => {
    if (document.referrer.search("https?://(.*)google.([^/?]*)") === 0) {
        return "Google";
    } else if (document.referrer.search("https?://(.*)bing.([^/?]*)") === 0) {
        return "Bing";
    } else if (document.referrer.search("https?://(.*)yahoo.([^/?]*)") === 0) {
        return "Yahoo";
    } else if (document.referrer.search("https?://(.*)facebook.([^/?]*)") === 0) {
        return "Facebook";
    } else if (document.referrer.search("https?://(.*)twitter.([^/?]*)") === 0) {
        return "Twitter";
    } else if (document.referrer.search("https?://(.*)instagram.([^/?]*)") === 0) {
        return "Instagram";
    } else if (document.referrer.search("https?://(.*)youtube.([^/?]*)") === 0) {
        return "Youtube";
    } else if (document.referrer.search("https?://(.*)jbelforttraining.([^/?]*)") === 0) {
        return "Funnel Redirect";
    } else if (document.referrer.search("https?://(.*)jordanbelfort.([^/?]*)") === 0) {
        return "Jordan Belfort";
    } else if (document.referrer.search("https?://(.*)linkedin.([^/?]*)") === 0) {
        return "LinkedIn";
    } else {
        return "Unknown";
    }
};

const fbSubIds = [
    "ad1video1UK",
    "sellmethispencopy",
    "sellmethispen",
    "sellmethispencop",
    "ship",
    "southkorea",
    "ad1video1LATAM",
    "thewolfworldwideretarget",
    "USMobCTSCamp6",
    "worldwidevideo",
    "41nottarget",
    "ad1video1",
    "USMobL2Camp14",
    "USMobL2Camp12",
    "pensquare",
    "worldwidecarvideo",
    "carvideoretarget",
    "lesson1newvideo",
    "USDeskL2Camp10",
    "USMobL2Camp3",
    "USMobL2Camp1",
    "USMobCTSCamp9",
    "USMobCTSCamp5",
    "USMobL2Camp15",
    "USDeskL2Camp6",
    "USMobL2Camp19",
    "USMobL2Camp7",
    "USMobL2Camp13",
    "ad1video1dupe",
    "USMobCTSCamp16",
    "USMobCTSCamp2",
    "USMobCTSCamp8",
    "USMobL2Camp2",
    "USMobL2Camp10",
    "USDeskL2Camp4",
    "angletestmastercopy",
    "USMobCTSCamp11",
    "USMobL2Camp18",
    "USDeskCTSCamp7",
    "USDeskL2Camp7",
    "USMobCTSCamp13",
    "USMobCTSCamp15",
    "USMobL2Camp17",
    "USMobL2Camp5",
    "USMobCTSCamp4",
    "USMobL2Camp6",
    "USMobCTSCamp3",
    "USMobCTSCamp1",
    "USMobL2Camp16",
    "USMobL2Camp20",
    "USMobCTSCamp14",
    "USMobCTSCamp12",
    "USMobCTSCamp18",
    "jordanandleoretarget",
    "USMobL2Camp9",
    "USMobCTSCamp19",
    "USMobCTSCamp10",
    "ad1video1CA",
    "USMobCTSCamp7",
    "USMobL2Camp8",
    "v3",
    "missingthepoint",
    "sellmethispen3",
    "worldwidefreetrial",
    "worldwidedailymotivation"
];

const legacySubIds = [
    "conversions",
    "JBT",
    "jordanandleoretarget5k",
    "v2",
    "v4564",
    "ga",
    "MC",
    "uktour19",
    "foldedarms",
    "v1",
    "v6",
    "v12",
    "v5",
    "v0",
    "v1.1",
    "v10",
];

export const getBaseSubId = (subId) => {
    if (!subId) {
        return "Unknown";
    }
    if (subId.startsWith("ggl")) {
        return "Google Ads";
    } else if (subId.startsWith("fb")) {
        return "Facebook Ads";
    } else if (subId.startsWith("FB")) {
        return "Facebook Ads";
    } else if (fbSubIds.includes(subId)) {
        return "Facebook Ads";
    } else if (subId.startsWith("yt")) {
        return "Youtube Ads";
    } else if (subId.startsWith("lkd")) {
        return "LinkedIn Ads";
    } else if (subId.startsWith("ntv")) {
        return "Native Ads";
    } else if (subId.startsWith("ig")) {
        return "Instagram Ads";
    } else if (subId.startsWith("oggl")) {
        return "Google Organic";
    } else if (subId.startsWith("ofb")) {
        return "Facebook Organic";
    } else if (subId.startsWith("olkd")) {
        return "Linkedin Organic";
    } else if (subId.startsWith("oyt")) {
        return "Youtube Organic";
    } else if (subId.startsWith("oig")) {
        return "Instagram Organic";
    } else if (subId.startsWith("v3")) {
        return "Facebook Ads";
    } else if (subId.startsWith("em")) {
        return "Re-targeting Emails";
    } else if (subId.startsWith("SMS")) {
        return "SMS";
    } else if (subId.startsWith("ma")) {
        return "Affiliates";
    } else if (subId.startsWith("Organic")) {
        return "Organic";
    } else if (legacySubIds.includes(subId)) {
        return "Legacy"
    }
    return "Unknown";
};

export const sendSmsPostBack = (smsId) => {
    axios.get("https://smsretry.com/api/post/sms/postback?id=" + smsId)
        .then(response => {
            console.log("Successfully sent sms postBack")
        })
        .catch(err => {
            console.warn("Failed to send sms postBack")
        });
};