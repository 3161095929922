import * as actionTypes from "./actionTypes";
import ReactPixel from "react-facebook-pixel";
import {productPrices, subscriptionProducts} from "../../shared/products";
import {setAffiliateProps} from "./mixPanel";
import {setContactSource} from "./contact";


export const joinButtonClicked = () => {
    return {
        type: actionTypes.JOIN_BUTTON_CLICKED
    }
};

export const goToHomePage = () => {
    return {
        type: actionTypes.GO_TO_HOME_PAGE
    }
};


export const learnClicked = () => {
    return {
        type: actionTypes.LEARN_CLICKED
    }
};

export const loadPage = (page) => {
    return {
        type: actionTypes.LOAD_PAGE,
        page: page
    }
};

export const pixelFired = () => {
    return {
        type: actionTypes.PIXEL_FIRED
    }
};

export const loadState = () => {
    return dispatch => {
        const page = localStorage.getItem('page');
        dispatch(loadPage(page));
        const system = localStorage.getItem('affiliate system');
        const id = localStorage.getItem('affiliate id');
        if (system !== null && id !== null) {
            dispatch(setAffiliateProps(system, id));
        }
        const source = localStorage.getItem('source');
        if (source != null) {
            dispatch(setContactSource(source))
        }
        const pixelHasFired = localStorage.getItem('pixelFired');
        if (pixelHasFired !== null) {
            dispatch(pixelFired());
        }
    };
};

export const firePixel = (products) => {
    return dispatch => {
        products.forEach(p => {
            if (subscriptionProducts.includes(p)) {
                const options = {
                    autoConfig: true, 	// set pixel's autoConfig
                    debug: false, 		// enable logs
                };
                ReactPixel.init('677343869578804', null, options);
                ReactPixel.init('1185032095006553', null, options);
                ReactPixel.track('Purchase', {currency: "USD", value: productPrices[p] / 100});
                window.obApi('track', 'Purchase', {currency: "USD", orderValue: productPrices[p] / 100});
                window._tfa.push({notify: 'event', name: 'make_purchase', currency: 'USD', revenue: productPrices[p] / 100});
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-980750786/L5jVCMKo1pABEMKj1NMD',
                    'transaction_id': ''
                });
                localStorage.setItem("pixelFired", "true");
                window.fbq('track', 'Purchase', {currency: "USD", value:productPrices[p]/100});
                dispatch(pixelFired());
            }
        });

    }
};
