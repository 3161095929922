import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    loading: false,
    error: null,
    token: null,
    productsSuccess: false
};

const getBrainTreeTokenStart = ( state, action ) => {
    return updateObject( state, {
        token: null,
        error: null,
        loading: true,
    } );
};

const getBrainTreeTokenSuccess = ( state, action ) => {
    return updateObject( state, {
        token: action.token,
        error: null,
        loading: false,
    } );
};

const getBrainTreeTokenFail = ( state, action ) => {
    return updateObject( state, {
        token: null,
        error: action.error,
        loading: false,
    } );
};

const setNonceStart = ( state, action ) => {
    return updateObject( state, {
        error: null,
        loading: true,
    });
};

const setNonceSuccess = ( state, action ) => {
    return state
};

const setNonceFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false,
    } );
};

const buyProductsStart = ( state, action ) => {
    return updateObject( state, {
        productsSuccess: false,
        error: null,
        loading: true,
    } );
};

const buyProductsSuccess = ( state, action ) => {
    return updateObject( state, {
        productsSuccess: true,
        error: null,
        loading: false,
    } );
};

const buyProductsFail = ( state, action ) => {
    return updateObject( state, {
        productsSuccess: false,
        error: action.error,
        loading: false,
    } );
};

const invalidateToken = ( state, action ) => {
    return updateObject( state, {
        token: null
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_BRAIN_TREE_TOKEN_START: return getBrainTreeTokenStart(state, action);
        case actionTypes.GET_BRAIN_TREE_TOKEN_SUCCESS: return getBrainTreeTokenSuccess(state, action);
        case actionTypes.GET_BRAIN_TREE_TOKEN_FAIL: return getBrainTreeTokenFail(state, action);
        case actionTypes.SET_BRAIN_TREE_NONCE_START: return setNonceStart(state, action);
        case actionTypes.SET_BRAIN_TREE_NONCE_SUCCESS: return setNonceSuccess(state, action);
        case actionTypes.SET_BRAIN_TREE_NONCE_FAIL: return setNonceFail(state, action);
        case actionTypes.BUY_BRAIN_TREE_PRODUCTS_START: return buyProductsStart(state, action);
        case actionTypes.BUY_BRAIN_TREE_PRODUCTS_SUCCESS: return buyProductsSuccess(state, action);
        case actionTypes.BUY_BRAIN_TREE_PRODUCTS_FAIL: return buyProductsFail(state, action);
        case actionTypes.INVALIDATE_BRAIN_TREE_TOKEN: return invalidateToken(state, action);
        default:
            return state;
    }
};

export default reducer;