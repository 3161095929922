import React, {Component} from 'react';
import classes from './Header.module.css';
import SlpLogo from "./SlpLogo";

class HeaderMain extends Component {
    render() {
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>

                    <SlpLogo height={this.props.height}/>
                </div>
                <div className={classes.mobileSubRoot}>
                    <SlpLogo height={220}/>
                </div>
            </div>
        )
    }
}

export default HeaderMain;