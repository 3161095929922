export const modules = {

    one: "Let’s start with the basics. Module 1 provides you an overview of the main psychological and technical elements that are necessary" +
        " to close a sale. You’ll learn about the 5 critical elements that need to align to successfully close any prospect. " +
        "Additionally, you’ll be introduced to the structure of a straight line sale, the psychology behind a prospect’s buying " +
        "decisions, and how to tap into those insights in a way that will increase your success rates.",
    two: "You’re constantly communicating, whether or not you realize it. This unconscious communication, also known as “the secret weapon of influence” is a powerful tool you can leverage to your benefit. You’ll learn about the importance of the first 4 seconds of the sale, what needs to be established, and the importance of it all.",
    three: "We’ll teach you how to use the Straight Line System at the highest level. You’ll be able to raise your awareness about the importance of state management as well as be prepared to manage your physiological-emotional state during the sales process. Remember, successful salespeople know how to maintain consistency throughout all forms of communication.",
    four: "Let’s dive even deeper. This module will provide you with an in-depth understanding of the first of the dual linchpins of unconscious communication, tonality. Furthermore, you will begin the process of mastering the practical application of each of the 10 core influencing tonalities.",
    five: "Learn about the other linchpin of unconscious communication, body language. You will learn the importance of body language and how it profoundly affects the outcome of a sale. After this lesson, you’ll know how to use body language to build massive rapport on both a conscious and unconscious level.",
    six: "Here, you’ll begin the process of mastering the art of sifting through a large pool of prospective buyers so that you can easily recognize the most qualified ones. Learn how to ask questions in a way that gathers insight while also building rapport with your audience.",
    seven: "This module will give you a step-by-step strategy for harnessing the power of vision focus. Learn how to combine the two crucial competencies of vision and focus into one core prowess. Create a compelling target to aim for, and ensure that you don’t become distracted by the obstacles that you will encounter along the way.",
    eight: "There’s an art and science behind constructing world-class straight line scripts. This is one of the most crucial aspects of mastering the Straight Line System. You’ll be guided through Jordan’s famous script-writing process. We’ll be breaking this down into easy-to-digest steps so that you can extract all the value with none of the stress. You’ll soon be able to construct the ideal script that is sure to set you up for success.",
    nine: "Understand your own personal standard. You’ll learn how to identify standards as it pertains to your sales success. We’ll teach you an extremely effective process for raising your standard with the goal of making it mirror your vision for your future.",
    ten: "Master the art of looping, which is what we call the “secret sauce” to the Straight Line System. Learn how to take your audience’s worries and flip the script. You’ll be able to increase your prospect’s confidence in your product or service and then seamlessly transition into a conversion, while maintaining rapport.",
    eleven: "Beliefs are powerful. They impact decision-making, whether it be good or bad. We’ll guide you through an extremely powerful process that will give you the assets to overcome your limiting beliefs and replace them with ones that empower you.",
    twelve: "Lastly, you’ll discover a series of proven strategies that will dramatically reduce customer attrition and turn first-time buyers into customers for life. We’ll break down a 7-step process for developing a never-ending stream of customer referrals."


};

export default modules