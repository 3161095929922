import React from 'react';
import classes from './ThankYouDivider.module.css';

export const properties = {
    props: {
        titleStart: "",
        spanOne: "",
        spanTwo: "",
        titleEnd: "",
        subTitle: "",
        quotes: ""

    }
};
const ThankYouDivider = (props) => (

<div>
    <div className={classes.sectionDivider}>
        <div>
            <div className={classes.title}>
                {props.titleStart}
                <span className={classes.yellow}> {props.spanOne}</span>
                <p className={classes.yellowTwo}> {props.spanTwo}</p>
                {props.titleEnd}
            </div>
            <div className={classes.container}>

                <div className={classes.subTitle}>
                    {props.subTitle}

                    <p className={classes.quotes}>{props.quotes}</p>

                </div>


            </div>


        </div>

    </div>

</div>
);
export default ThankYouDivider;