import React, {Component} from 'react';
import {connect} from "react-redux";

class Split extends Component {

    render() {
        if (Object.keys(this.props.splitChoices).length === 0 && this.props.splitChoices.constructor === Object) {
            return null;
        }
        const profileName = this.props.profile.name;
        const choice = this.props.splitChoices[profileName];
        const child = Array.isArray(this.props.children) ? this.props.children[choice] : this.props.children;
        let displayChild = child;
        if (typeof child.type !== 'string') {
            const profileValues = this.props.profile.values[choice].props;
            const values = {};
            Object.assign(values, profileValues);
            const addProfiles = this.props.additionalProfiles;
            if (addProfiles) {
                addProfiles.forEach(p => {
                    const index = this.props.splitChoices[p.name];
                    Object.assign(values, p.values[index].props)
                })
            }

            displayChild = React.cloneElement(child, {split: values});
        }
        return (displayChild);
    }
}

const mapStateToProps = (state) => {
    return {
        splitChoices: state.split.choices,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};


export default connect(mapStateToProps, mapDispatchToProps)(Split);