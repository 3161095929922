import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
    creditCardSuccess: false,
    paymentType: null,
    paypalProducts: [],
    paypalProductsLoaded: false
};

const creditStart = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    })
};

const creditSuccess = (state, action) => {
    return updateObject(state, {
        error: null,
        creditCardSuccess: true,
        loading: false
    })
};

const creditFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const setPaymentType = (state, action) => {
    localStorage.setItem('paymentType', action.paymentType);
    return updateObject(state, {
        paymentType: action.paymentType
    })
};

const addPaypalProduct = (state, action) => {
    localStorage.setItem('paypalProducts', action.products.join(";"));
    return updateObject(state, {
        paypalProducts: action.products
    })
};

const loadPage = (state, action) => {
    let productsString = localStorage.getItem('paypalProducts');
    if (productsString === null) {
        return updateObject(state, {
            paypalProductsLoaded: true
        })
    }
    const products = productsString.split(";");
    return updateObject(state, {
        paypalProducts: products,
        paypalProductsLoaded: true
    })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_CREDIT_INFO_START: return creditStart(state, action);
        case actionTypes.SET_CREDIT_INFO_SUCCESS: return creditSuccess(state, action);
        case actionTypes.SET_CREDIT_INFO_FAIL: return creditFail(state, action);
        case actionTypes.SET_PAYMENT_TYPE: return setPaymentType(state, action);
        case actionTypes.ADD_PAYPAL_PRODUCT: return addPaypalProduct(state, action);
        case actionTypes.LOAD_PAGE_CREDITS: return loadPage(state, action);
        default:
            return state;
    }
};

export default reducer;