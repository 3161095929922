import React, {Component} from 'react';
import classes from './CreditCardForm.module.css';
import {IoMdCheckmark} from "react-icons/io";
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import CreditForm from "../CreditForm/CreditForm";
import * as productTags from "../../../../shared/products";
import {
    STRAIGHT_LINE_CERTIFICATION,
    STRAIGHT_LINE_CERTIFICATION_SUB_6,
    STRAIGHT_LINE_CERTIFICATION_SUBT
} from "../../../../shared/products";
import Spinner from "../../../Spinner/Spinner";
import {FaRegCheckSquare, FaRegSquare} from "react-icons/fa";
import SecureImage from "../../../../assets/images/secure-logos.png";
import {injectStripe} from "react-stripe-elements";
import {sendAnalytics} from "../../../../shared/utility";
import ReactPixel from "react-facebook-pixel";

const currentYear = new Date().getFullYear();

class CreditCardForm extends Component {

    state = {
        annualSelected: null,
        fastTrackSelected: false,
        monthlySelected: null,
        quarterlySelected: null,
        yearSelected: currentYear.toString(),
        cardNumber: '',
        cvc: '',
        itemError: '',
        cardNumberError: '',
        cvcError: '',
        papRef: React.createRef(),
        tag: ''
    };

    componentDidMount() {
        this.props.sendEvent("credit-form");
        sendAnalytics("Funnel Credit-card form page");
        window.PostAffTracker.writeCookieToCustomField('pap_tdx8v2s5a');
    }

    handleAnnualClick(event) {
        this.setState({
            ...this.state,
            annualSelected: true,
            quarterlySelected: false,
            monthlySelected: false
        });

    }

    handleMonthlyClick(event) {
        this.setState({
            ...this.state,
            annualSelected: false,
            quarterlySelected: false,
            monthlySelected: true

        });

    }

    handleQuarterlyClick(event) {
        this.setState({
            ...this.state,
            annualSelected: false,
            monthlySelected: false,
            quarterlySelected: true

        });

    }

    handleFastTrackClick(event) {
        this.setState({
            ...this.state,
            fastTrackSelected: !this.state.fastTrackSelected
        })
    }

    static validateItem(val) {
        if (val === null) {
            return 'Select a product';
        }
        return '';
    }


    submit() {
        ReactPixel.track('AddPaymentInfo', {content_name: 'slscert'});
        sendAnalytics("Funnel credit-card form submit");
        this.props.setPaymentType("stripe");
        let itemError = CreditCardForm.validateItem(this.state.annualSelected);

        if (itemError !== '') {
            this.setState({
                ...this.state,
                itemError: itemError,
            });
            return;
        }

        let products = [];
        if (this.state.annualSelected) {
            products.push(this.props.split.annualProduct);
            this.props.sendEvent(STRAIGHT_LINE_CERTIFICATION);
            this.setState({
                tag: STRAIGHT_LINE_CERTIFICATION
            })

        } else if (this.state.quarterlySelected) {
            products.push(this.props.split.quarterlyProduct);
            this.props.sendEvent(STRAIGHT_LINE_CERTIFICATION_SUB_6);
            this.setState({
                tag: STRAIGHT_LINE_CERTIFICATION_SUB_6
            })

        } else {
            products.push(this.props.split.monthlyProduct);
            this.props.sendEvent(STRAIGHT_LINE_CERTIFICATION_SUBT);
            this.setState({
                tag: STRAIGHT_LINE_CERTIFICATION_SUBT
            })
        }
        if (this.state.fastTrackSelected) {
            products.push(productTags.FAST_TRACK);
            this.props.sendEvent("cart-fast-track")
        }

        this.props.stripe.createToken().then(({token}) => {
            this.props.setCreditCardInfo({
                token: token.id,
                pap: this.state.papRef.current.value,
                tag: this.state.tag
            }, products);
        });

    }

    render() {

        if (this.props.creditLoading) {
            return (
                <div className={classes.root}>
                    <div className={classes.spinnerBox}>
                        <Spinner/>
                    </div>
                </div>
            )
        }

        let paymentForm = <CreditForm/>;

        let stripeButton = <button className={classes.submit} onClick={() => this.submit()}>Complete Order</button>;


        const productList = [];
        if (this.state.fastTrackSelected) {
            productList.push(productTags.FAST_TRACK);
        }
        if (this.state.annualSelected === true && this.state.quarterlySelected === false && this.state.monthlySelected === false) {
            productList.push(this.props.split.annualProduct)
        } else if (this.state.monthlySelected === true && this.state.quarterlySelected === false && this.state.annualSelected === false) {
            productList.push(this.props.split.monthlyProduct)
        } else if (this.state.quarterlySelected === true && this.state.monthlySelected === false && this.state.annualSelected === false) {
            productList.push(this.props.split.quarterlyProduct)
        }


        return (
            <div className={classes.root}>

                <div className={classes.itemButtons}>
                    <input type="hidden" name="pap_custom" value={this.state.papCode} id="pap_tdx8v2s5a"
                           ref={this.state.papRef}/>
                    <button
                        className={
                            classes.itemButton +
                            (this.state.annualSelected ? ' ' + classes.activeButton : '') +
                            (this.state.itemError !== '' && this.state.annualSelected === null ? ' ' + classes.errorItemButton : '')
                        }
                        onClick={(event) => this.handleAnnualClick(event)}
                    >
                        <div className={classes.mostPopular}>Most Popular</div>
                        <div className={classes.checkHolder}>
                            {this.state.annualSelected ? <FaRegCheckSquare className={classes.check}/> :
                                <FaRegSquare className={classes.check}/>}
                        </div>
                        <div className={classes.itemButtonText}><b>One easy payment of $2,495</b>
                        </div>
                        <div className={classes.bannerBox}>
                            <div className={classes.banner}>50% Off</div>
                        </div>
                        <div className={classes.fakeButton}>Select Plan</div>
                    </button>

                    <button
                        className={
                            classes.itemButton +
                            (this.state.monthlySelected ? ' ' + classes.activeButton : '') +
                            (this.state.itemError !== '' && this.state.itemSelected === null ? ' ' + classes.errorItemButton : '')
                        }
                        onClick={(event) => this.handleMonthlyClick(event)}
                    >
                        <div className={classes.checkHolder}>
                            {this.state.monthlySelected ? <FaRegCheckSquare className={classes.check}/> :
                                <FaRegSquare className={classes.check}/>}
                        </div>
                        <div className={classes.itemButtonText}><p>Pay $998 for 3 months</p>
                        </div>
                        <div className={classes.bannerBox}>
                            <div className={classes.bannerLess}>40% Off</div>
                        </div>
                        <div className={classes.fakeButton}>Select Plan</div>
                    </button>



                </div>
                {this.state.itemError !== '' && this.state.annualSelected === null ?
                    <div className={classes.itemError}>{this.state.itemError}</div> : null}
                {paymentForm}
                <div className={classes.fastTrackBlock}>
                    <div className={classes.ftCheckBlock}>
                        <div className={classes.ftCheckRow}>
                            <div className={classes.ftCheckLineBoxLeft}>
                                <div className={classes.ftCheckLine}/>
                            </div>
                            <div className={classes.ftCheckbox} onClick={(event) => this.handleFastTrackClick(event)}>
                                {this.state.fastTrackSelected ? <IoMdCheckmark className={classes.ftCheck}/> : null}
                            </div>
                            <div className={classes.ftCheckLineBoxRight}>
                                <div className={classes.ftCheckLine}/>
                            </div>
                        </div>
                        <p className={classes.offerTitle}>Yes, I also want the Straight Line System Fast Track (SAVE
                            $200)</p>
                    </div>
                    <p className={classes.offerText}><b>ONE TIME OFFER</b>: Master the most EFFECTIVE sales system
                        ever created and dramatically increase your closing rate! This offer for $97.00 is shown
                        ONLY on this page on this website and is available only right now. The results people have
                        found from this system are dramatic and life changing to say the least. Take advantage of
                        this offer now and become a Master in the art of Sales & Persuasion. This OFFER is backed by
                        a 30 day money back guarantee. *This is the training system used by sales teams for
                        THOUSANDS of multi-million dollar companies.</p>
                </div>
                {this.props.creditError ? <div className={classes.serverError}>{this.props.creditError}</div> : null}
                {stripeButton}

                <p className={classes.safeText}>* 100% Secure & Safe Payments *</p>
                <div className={classes.imageBox}><img className={classes.image} src={SecureImage}
                                                       alt="security verified"/></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        creditLoading: state.credit.loading,
        creditError: state.credit.error,
        paymentType: state.credit.paymentType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCreditCardInfo: (creditInfo, products) => dispatch(actions.setCreditInfo(creditInfo, products)),
        setPaymentType: (paymentType) => dispatch(actions.setPaymentType(paymentType)),
        sendEvent: (eventTag) => dispatch(actions.sendAnalytics(eventTag))
    };
};


export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(CreditCardForm));