import * as actionTypes from "./actionTypes";
import {postData, postMailRequest} from "../../shared/utility";

export const setContactInfoStart = (contactInfo) => {
    return {
        type: actionTypes.SET_CONTACT_INFO_START,
        info: contactInfo
    }
};

export const setContactInfoSuccess = () => {
    return {
        type: actionTypes.SET_CONTACT_INFO_SUCCESS
    }
};

export const setContactInfoFail = (error) => {
    return {
        type: actionTypes.SET_CONTACT_INFO_FAIL,
        error: error
    }
};

export const setContactSource = (source) => {
    localStorage.setItem("src", source);
    return {
        type: actionTypes.SET_CONTACT_SOURCE,
        source: source
    }
};

export const setContactInfo = (contactInfo) => {
    return dispatch => {
        dispatch(setContactInfoStart(contactInfo));

        localStorage.setItem("email", contactInfo.email);
        let papId = localStorage.getItem("PAPVisitorId");
        let maId = localStorage.getItem("maId");
        let maClickId = localStorage.getItem("maClickId");
        const data = {
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
            email: contactInfo.email,
            phone: contactInfo.phone ? contactInfo.phone : "",
            source: contactInfo.source,
            papId: papId,
            maId: maId,
            maClickId: maClickId,
            allowExists: contactInfo.allowExists
        };

        postData(
            "/user",
            data,
            response => {
                console.log("userdata", response.data);
                localStorage.setItem('userId', response.data.userId);
                dispatch(setContactInfoSuccess());
            },
            err => {
                dispatch(setContactInfoFail(err.response.data.message));
            });
    };
};


export const setContactInfoInquiry = (contactInfo) => {
    return dispatch => {
        dispatch(setContactInfoStart(contactInfo));

        localStorage.setItem("email", contactInfo.email);
        let papId = localStorage.getItem("PAPVisitorId");
        let maId = localStorage.getItem("maId");
        let maClickId = localStorage.getItem("maClickId");
        const data = {
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
            email: contactInfo.email,
            phone: contactInfo.phone ? contactInfo.phone : "",
            source: contactInfo.source,
            papId: papId,
            maId: maId,
            maClickId: maClickId,
            allowExists: contactInfo.allowExists
        };
        let email = contactInfo.email;
        let name = contactInfo.firstName + ' ' + contactInfo.lastName;
        let phone = contactInfo.phone;


        postMailRequest(
            "/contact/send",
            {
                subject: "New Certification Inquiry",
                body: 'email: ' + email + ', ' +
                    'full name: ' + name + ', ' +
                    'phone: ' + phone

            }
        );

        postData(
            "/user",
            data,
            response => {
                localStorage.setItem('userId', response.data);
                dispatch(setContactInfoSuccess());
            },
            err => {
                dispatch(setContactInfoFail(err.response.data.message));
            });
    };
};

