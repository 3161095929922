import React, {Component} from 'react';
import classes from './ModuleList.module.css';
import Buttons from "../ButtonGroup/Buttons";
import Divider from "../Divider/Divider";
import Leo from "../Leo/Leo";
import listModule from '../../../assets/images/listModule.png';
import jbLesson from '../../../assets/images/jbLesson.png';
import slscExam from '../../../assets/images/slscExam.png';
import modules from './Modules';

class ModuleList extends Component {

    render() {
        return (
            <div className={classes.mainContainer}>

                <div className={classes.root}>

                    <div className={classes.container}>

                        <div className={classes.iconSection}>
                            <div className={classes.headerContainer}>
                                <h1 className={classes.moduleHeader}>
                                     WHAT YOU'LL LEARN
                                </h1>
                            </div>
                            <div className={classes.left}>

                                <div className={classes.iconContainer}>
                              
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 1:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Cracking the Code
                                            </p>
                                        </div>
                                        <p> {modules.one}</p>
                                    </div>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 2:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Unconscious Communication
                                            </p>
                                        </div>

                                        <p> {modules.two}</p>
                                    </div>
                                </div>
                                <div className={classes.iconContainer}>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 3:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                State Management
                                            </p>
                                        </div>
                                        <p>{modules.three}</p>
                                    </div>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 4:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Advanced Tonality
                                            </p>
                                        </div>
                                        <p>{modules.four} </p>
                                    </div>

                                </div>

                                <div className={classes.imageRow}>
                                    <img className={classes.mainImage} src={listModule} alt={"module list"}/>

                                </div>


                                <div className={classes.iconContainer}>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 5:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Body Language
                                            </p>
                                        </div>
                                        <p>{modules.five} </p>
                                    </div>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 6:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Prospecting
                                            </p>
                                        </div>
                                        <p>{modules.six}</p>
                                    </div>

                                </div>
                                <div className={classes.iconContainer}>

                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 7:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Vision
                                            </p>
                                        </div>
                                        <p>{modules.seven}</p>
                                    </div>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 8:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Script Building
                                            </p>
                                        </div>
                                        <p>{modules.eight}</p>
                                    </div>
                                </div>
                                <div className={classes.imageRow}>
                                    <img className={classes.mainImage} src={jbLesson} alt={"jb lesson"}/>

                                </div>
                                <div className={classes.iconContainer}>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 9:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Standards
                                            </p>
                                        </div>
                                        <p>{modules.nine} </p>
                                    </div>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 10:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Looping
                                            </p>
                                        </div>
                                        <p>{modules.ten}</p>
                                    </div>
                                </div>

                                <div className={classes.iconContainer}>
                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 11:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Beliefs
                                            </p>
                                        </div>
                                        <p>{modules.eleven} </p>
                                    </div>


                                    <div className={classes.listText}>
                                        <div className={classes.headingRow}>
                                            <h1 className={classes.headingText}>
                                                MODULE 12:
                                            </h1>
                                            <p className={classes.subHeadText}>
                                                Customers for Life
                                            </p>
                                        </div>
                                        <p>{modules.twelve}</p>
                                    </div>


                                </div>

                                <div className={classes.imageRow}>
                                    <img className={classes.mainImage} src={slscExam} alt={"jb lesson"}/>

                                </div>


                                <Buttons
                                    buttonText={"Fill Out My Application Now!"}
                                />

                            </div>
                            <div className={classes.divider}>
                                <Divider
                                    titleStart="WE "
                                    spanOne="STAND BY ALL "
                                    titleEnd="OF OUR PRODUCTS"
                                    subTitle="Unconditional 30-day Money Back Guarantee"
/*
                                    quotes='No Risk... No Downside... Unlimited Upside...'
*/


                                />
                            </div>


                        </div>

                    </div>

                </div>


                <div className={classes.mobileRoot}>
                    <div className={classes.headingRow}>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 1:</h2>
                                <h1 className={classes.headingText}>Cracking the Code</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.one}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 2:</h2>
                                <h1 className={classes.headingText}>Unconscious Communnication</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.two}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 3:</h2>
                                <h1 className={classes.headingText}>State Management</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.three}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 4:</h2>
                                <h1 className={classes.headingText}>Advanced Tonality</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.four}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 5:</h2>
                                <h1 className={classes.headingText}>Body Language</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.five}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 6:</h2>
                                <h1 className={classes.headingText}>Prospecting</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.six}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 7:</h2>
                                <h1 className={classes.headingText}>Vision</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.seven}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 8:</h2>
                                <h1 className={classes.headingText}>Script Building</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.eight}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 9:</h2>
                                <h1 className={classes.headingText}>Standards</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.nine}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 10:</h2>
                                <h1 className={classes.headingText}>Looping</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.ten}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 11:</h2>
                                <h1 className={classes.headingText}>Beliefs</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.eleven}
                            </p>
                        </div>
                        <div className={classes.iconRow}>


                            <div>
                                <h2 className={classes.subText}>Module 12:</h2>
                                <h1 className={classes.headingText}>Customers for Life</h1>

                            </div>
                        </div>
                        <div className={classes.newRow}>
                            <p className={classes.mobileText}>
                                {modules.twelve}
                            </p>
                        </div>


                        <div className={classes.newRow}>
                            <Leo/>
                        </div>
                        <Buttons
                            buttonText={"Fill Out My Application Now!"}
                        />



                    </div>


                </div>
                <div className={classes.mobileDivider}>
                    <Divider
                        titleStart="WE "
                        spanOne="STAND BY ALL "
                        titleEnd="OF OUR PRODUCTS"
                        subTitle="Unconditional 30-day Money Back Guarantee"
/*
                        quotes='No Risk... No Downside... Unlimited Upside...'
*/


                    />
                </div>
            </div>


        )

    }
}

export default ModuleList;