import React, {Component} from 'react';
import ReactPlayer from "react-player";

class VimeoPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0, playing: false};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if (this.props.playing) {
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    playing: true
                });
            }, 1000);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        const innerWidth = window.innerWidth;
        let width = this.props.width;
        let height = this.props.height;
        if (innerWidth < this.props.threshold) {
            width = this.props.multiplier * innerWidth;
            height = (width / this.props.width) * this.props.height;
        }
        this.setState({width: width, height: height});
    }

    render() {
        return (
            <ReactPlayer
                url={this.props.url}
                width={this.state.width}
                height={this.state.height}
                playing={this.state.playing}
                preload={this.props.preload}
                volume={1}
            />
        );
    }
}

export default VimeoPlayer;