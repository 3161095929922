import React, {Component} from 'react';
import classes from './TestimonialQuote.module.css';
import quote from '../../../assets/images/quote.png';

class TestimonialQuote extends Component {

    render() {
        return (


            <div className={classes.root}>
                <div className={classes.subRoot}>
                <div className={classes.quote}>
                    <img className={classes.quoteImage} width="44" alt="quotes" src={quote}/>

                    “Your course has changed my life. I am now the #1 salesman in my company. I have been promoted to
                    Team
                    Leader and am now training others to close like a Wolf. Pretty amazing, to say the least! THANK YOU,
                    JB!”
                    <div className={classes.memberName}> - Alex Smith, Wolfpack Member</div>
                </div>
                <div className={classes.quote}>
                    <img className={classes.quoteImage} width="44" alt="quotes" src={quote}/>

                    "My Saturday sales were excellent! I sold a new boat for 61k using only my sales skills. The customer
                    came in to buy garden equipment, but I sold the boat to them instead!"
                    <div className={classes.memberName}>- Rasmus Sundstrum, Wolfpack Member</div>
                </div>
                </div>
            </div>


        )

    }
}

export default TestimonialQuote;