import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    name: null,
    email: null,
    phone: null,
    contactSuccess: false,
    loading: false,
    error: null,
    source: null
};

const contactInfoStart = ( state, action ) => {
    return updateObject( state, {
        name: action.info.name,
        email: action.info.email,
        phone: action.info.phone,
        contactSuccess: false,
        loading: true,
        error: null
    } );
};

const contactInfoSuccess = ( state, action ) => {
    return updateObject( state, {
        contactSuccess: true,
        loading: false,
        error: null
    } );
};

const contactInfoFail = ( state, action ) => {
    return updateObject( state, {
        contactSuccess: false,
        loading: false,
        error: action.error
    } );
};

const setSource = ( state, action ) => {
    localStorage.setItem("source", action.source);
    return updateObject( state, {
        source: action.source
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_CONTACT_INFO_START: return contactInfoStart(state, action);
        case actionTypes.SET_CONTACT_INFO_SUCCESS: return contactInfoSuccess(state, action);
        case actionTypes.SET_CONTACT_INFO_FAIL: return contactInfoFail(state, action);
        case actionTypes.SET_CONTACT_SOURCE: return setSource(state, action);
        default:
            return state;
    }
};

export default reducer;