import React, {Component} from 'react';
import 'react-image-lightbox/style.css';
import PlayButton from "../../../assets/images/play.png";
import classes from "../Landing.module.css";
import ReactImageVideoLightbox from "react-image-lightbox-dimensions";


export default class LightboxExample extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            width: window.innerWidth * 0.75,
            height: window.innerHeight * 0.7937
        };


    };

    componentDidMount() {

        window.addEventListener('resize', this.handleResize);
        window.addEventListener("keypress", this.handleKeyPress);

    }

    componentWillMount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener("keypress", this.handleKeyPress);

    }

    handleResize = () => {

        if (window.innerWidth <= 500) {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight,

            })


        } else{
            this.setState({
                width: window.innerWidth * 0.75,
                height: window.innerHeight * 0.7937,

            })

        }

    };


    render() {

        const {isOpen} = this.state;

        return (
            <div>
                <button id="play" className={classes.playButtonBox} type="button"  onClick={() =>
                    this.setState({isOpen: true})
                }>

                    <div className={classes.playButton}>

                    <img width={40} height={40} src={PlayButton}
                         alt="Gold Play"/>
                    </div>

                    <div className={classes.mobilePlay}>
                        <img width={40} height={40} src={PlayButton}
                             alt="Gold Play"/>
                    </div>
                </button>

                {isOpen && (

                    <ReactImageVideoLightbox
                        data={[
                            {
                                url: 'https://player.vimeo.com/video/531981142?autoplay=1',
                                type: 'video',
                                altTag: 'Jb Reel',
                                width: this.state.width,
                                height: this.state.height


                            }
                        ]}
                        startIndex={0}
                        showResourceCount={false}
                        showLeftScroll={0}
                        showRightScroll={false}
                        onCloseCallback={() => this.setState({isOpen: false})}
                        autoPlay={true}


                    />

                )}
            </div>

        );


    }

}

