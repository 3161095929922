import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    choices: {}
};

const splitTestsLoaded = ( state, action) => {
    return updateObject( state, {
        choices: action.data
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SPLIT_TESTS_LOADED: return splitTestsLoaded(state, action);
        default:
            return state;
    }
};

export default reducer;