import React, {Component} from 'react';
import classes from './ContactFormPage.module.css';

import ContactFormBlock from "./ContactFormBlock/ContactFormBlock";
import {connect} from "react-redux";
import {FaArrowLeft} from "react-icons/fa";
import * as actions from "../../store/actions";
import Split from "../SplitTest/Split";
import * as splitProfiles from "../../shared/splitProfiles";
import Header from "../Header/Header";

class ContactFormPage extends Component {

    state = {
        expired: false,
        expiredLoaded: false
    };

    componentDidMount() {
        const offerExpired = localStorage.getItem("offerExpired");
        const startTime = localStorage.getItem("timer");
        const elapsed = (new Date()).getTime() - Number(startTime) > 30 * 60 * 1000;
        if (offerExpired === 'true' && !elapsed) {
            this.setState({expired: true, expiredLoaded: true});
        } else if (elapsed) {
            localStorage.removeItem("timer");
            localStorage.removeItem("offerExpired");
            this.setState({expired: false, expiredLoaded: true});
        } else {
            this.setState({expired: false, expiredLoaded: true});
        }
        window.scrollTo(0, 0);
    }

    setExpired() {
        localStorage.setItem("offerExpired", 'true');
        this.setState({expired: true});
    }

    render() {
        return (
            <div className={classes.root}>
                <div >
                    <Header height={250}/>
                </div>
                <div className={classes.backButtonPlaceholder}/>
                <button className={classes.backButton} onClick={() => this.props.goToHomePage()}>
                    <FaArrowLeft className={classes.backButtonIcon}/>
                    <div className={classes.backButtonText}>Back To Main Page</div>
                </button>
                <div className={classes.backgroundOverlay}/>
                <h1 className={classes.title}>Welcome to Straight Line Sales Certification!</h1>
                <div className={classes.subRoot}>
                    <div className={classes.block + " " + classes.left}>

                    </div>
                    <div className={classes.block + " " + classes.right}>
                        <h2 className={classes.rightTitle}>
                            Congrats! By signing up today, you are invited to attend a live, one-day training with Jordan,
                            where he will personally hand you your diploma, and pose for a keepsake photo. </h2>
                        <Split profile={splitProfiles.threeVsSeven}>
                            <ContactFormBlock/>
                        </Split>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToHomePage: () => dispatch(actions.goToHomePage())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormPage);