import React from 'react';
import classes from './Spinner.module.css';
import Spinner from "./Spinner";

const PageSpinner = (props) => {
    return (
        <div className={classes.backdrop}><div className={classes.spinnerBox}><Spinner/></div></div>
    );
};

export default PageSpinner;