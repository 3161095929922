import * as actionTypes from "./actionTypes";

export const sendMixPanelEvent = (tag) => {
    return {
        type: actionTypes.SEND_EVENT,
        tag: tag
    }
};

export const setAffiliateProps = (system, id) => {
    localStorage.setItem("affiliate system", system);
    localStorage.setItem("affiliate id", id);
    return {
        type: actionTypes.AFFILIATE_LOADED,
        id: id,
        system: system
    }
};

export const sendEvent = (tag) => {
    return dispatch => {
        dispatch(sendMixPanelEvent(tag));
    }
};
