import React, {Component} from 'react';
import classes from "./Manual.module.css";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {FaCheck} from "react-icons/fa";
import * as productTags from '../../shared/products';
import {SO_MANUAL} from '../../shared/products';
import PageSpinner from "../Spinner/PageSpinner";
import {MANUAL, MANUAL_PAGE, SKIP_MANUAL} from "../../shared/events";
import VimeoPlayer from "../Vimeo/VimeoPlayer";
import Aux from "../../hoc/Aux";
import _ from "lodash";
import CreditForm from "../ContactFormPage/ContactFormBlock/CreditForm/CreditForm";

class Manual extends Component {

    constructor(props) {
        super(props);
        const random = Math.round(Math.random() * 50);
        const manualsLeft = random < 15 ? 15 : random;
        this.state = {
            random: manualsLeft,
            brainTreeInstance: null,
            brainTreeReady: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sendEvent(MANUAL_PAGE);
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        })
    }

    skip() {
        this.props.skip();
        this.props.sendEvent(SKIP_MANUAL);
    }

    buy() {
        this.props.sendEvent(MANUAL);
        if (this.state.brainTreeInstance) {
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    // Do something with the error
                } else {
                    this.submitNonce(payload.nonce)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    submitNonce(nonce) {
        this.props.setNonceAndBuy(nonce, [SO_MANUAL], this.props.transactionProperties)
    }

    render() {

        const buttons = <Aux>
            <button className={classes.button} disabled={!this.state.brainTreeReady} onClick={_.debounce(() => this.buy(), 300, {
                'leading': true,
                'trailing': false
            })}>
                Yes, I want it!
            </button>
            <button className={classes.skipButton} onClick={() => this.skip()}>
                No, thank you.
            </button>
        </Aux>;

        return (
            <div className={classes.root}>
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                <p className={classes.notice}>Last offer! Only 100 will be sold. Only {this.state.random} left</p>
                <div className={classes.subRoot}>
                    <div className={classes.videoBlock}>
                        <div className={classes.videoSubBlock}>
                            <h1>The Original Stratton Oakmont Manual that was auctioned off for $23,000</h1>
                            <div className={classes.video}>
                                <VimeoPlayer
                                    width={775}
                                    height={436}
                                    threshold={1100}
                                    multiplier={0.85}
                                    url="https://player.vimeo.com/video/322718154"
                                />
                            </div>
                            <div className={classes.creditBlock}>
                                <CreditForm
                                    brainTreeInstance={this.state.brainTreeInstance}
                                    setBrainTreeInstance={(instance) => this.setBrainTreeInstance(instance)}
                                    price={49}
                                    product="Stratton Oakmont Manual"
                                />
                            </div>
                            <div className={classes.desktopButton}>
                                {buttons}
                            </div>
                        </div>
                    </div>
                    <div className={classes.checksBlock}>
                        <div className={classes.checksSubBlock}>
                            <h2 className={classes.checksTitle}>The EXACT manual that was used all those years ago (in
                                the
                                movie) that took all those kids off the streets and turned them into MILLIONAIRES!</h2>
                            <ul className={classes.checks}>
                                <li className={classes.check}><FaCheck className={classes.checkIcon}/><p>Learn how to
                                    develop perfect scripts</p></li>
                                <li className={classes.check}><FaCheck className={classes.checkIcon}/><p>Discover the
                                    subtleties that make a script TRULY PERFECT to close anyone who's closable</p></li>
                                <li className={classes.check}><FaCheck className={classes.checkIcon}/><p>Over 70 + pages
                                    written from the Wolf of Wall Street himself.</p></li>
                                <li className={classes.check}><FaCheck className={classes.checkIcon}/><p>Tried and
                                    tested
                                    against the most challenging people to close on WALL STREET!</p></li>
                            </ul>
                            <p className={classes.regularPrice}>REGULAR PRICE: Sold at auction for $23,500</p>
                            <p className={classes.offerText}>ONE TIME OFFER for brand new members:</p>
                            <p className={classes.offerPrice}>Only $49</p>
                            <div className={classes.desktopButton}>
                                {buttons}
                            </div>
                            <p className={classes.offerSubtext}>This offer is available only on this page and right now
                                for
                                NEW members like you.
                                Not available any other time or place. <b>(You will not see this page or offer
                                    again)</b>
                            </p>
                        </div>
                    </div>
                    <div className={classes.buttonRow}>
                        {buttons}
                    </div>
                </div>
                <div className={classes.buttonPlaceholder}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.braintree.loading,
        error: state.braintree.error,
        transactionProperties: state.mixPanel.superProps
    };
};

const mapDispatchToProps = dispatch => {
    return {
        skip: () => dispatch(actions.addProductSkip(productTags.SO_MANUAL)),
        sendEvent: (eventTag) => {dispatch(actions.sendAnalytics(eventTag)); dispatch(actions.sendEvent(eventTag))},
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products, transactionProperties))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Manual);