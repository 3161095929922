import React, {Component} from 'react';
import classes from './ContactFormBlock.module.css';
import {connect} from "react-redux";
import CreditCardForm from "./CreditCardForm/CreditCardForm";
import {Elements} from "react-stripe-elements";
import ContactForm from "./ContactForm/ContactForm";

class ContactFormBlock extends Component {

    render() {
        return (
            <div className={classes.root}>
                <div className={classes.phoneProgress}>
                    <h2>Welcome to Straight Line Sales Certification!</h2>
                </div>
                <div className={classes.progressRow}>
                    <div className={classes.progressBlock}>
                        <div className={classes.stepNumber}>1</div>
                        <div className={classes.stepText}>
                            <p className={classes.stepTitle + (this.props.contactSuccess ? '' : ' ' + classes.stepTitleActive)}>
                                Your Contact Info
                            </p>
                        </div>
                    </div>
                    <div className={classes.progressBlock}>
                        <div className={classes.stepNumber}>2</div>
                        <div className={classes.stepText}>
                            <p className={classes.stepTitle + (this.props.contactSuccess ? ' ' + classes.stepTitleActive : '')}>
                                Your Payment Info
                            </p>
                        </div>
                    </div>
                </div>
                {this.props.contactSuccess ?
                    <Elements><CreditCardForm split={this.props.split}/></Elements> : <ContactForm/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormBlock);