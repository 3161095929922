import React, {Component} from 'react';
import classes from './Leo.module.css'
import {FaLockOpen} from "react-icons/fa";
import Carousel from "../../Carousel/Carousel";

class Leo extends Component {

    render() {
        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <div className={classes.left}>

                    </div>
                    <div className={classes.right}>
                        <Carousel
                            data={[
                                {type: 'video', url: "https://player.vimeo.com/video/345126741"}
                            ]}
                            width={550}
                            height={300}
                        />
                        <button className={classes.button + " " + classes.mobile} onClick={() => this.props.buttonClicked()}>
                            <h3><FaLockOpen/>Start Your Free Trial Now</h3>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Leo;