import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const addProductStart = (productTag) => {
    return {
        type: actionTypes.ADD_PRODUCT_START,
        tag: productTag
    };
};

export const addProductSuccess = (productTag) => {
    return {
        type: actionTypes.ADD_PRODUCT_SUCCESS,
        tag: productTag
    };
};

export const addProductFail = (productTag, error) => {
    return {
        type: actionTypes.ADD_PRODUCT_FAIL,
        tag: productTag,
        error: error
    };
};

export const getProductsStart = () => {
    return {
        type: actionTypes.GET_PRODUCTS_START
    };
};

export const getProductsSuccess = (products) => {
    return {
        type: actionTypes.GET_PRODUCTS_SUCCESS,
        products: products
    };
};

export const getProductsFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCTS_FAIL,
        error: error
    };
};

export const addProductSkip = (productTag) => {
    return {
        type: actionTypes.ADD_PRODUCT_SKIP,
        tag: productTag
    };
};

export const addPaypalProduct = (products) => {
    return {
        type: actionTypes.ADD_PAYPAL_PRODUCT,
        products: products
    };
};

export const addProduct = (productTag) => {
    return dispatch => {
        dispatch(addProductStart(productTag));
        const userId = localStorage.getItem('userId');
        postData(
            "/product/" + userId + "/" + productTag,
            {},
            response => {
                dispatch(addProductSuccess(productTag));
            },
            err => {
                dispatch(addProductFail(productTag, err));
            });
    };
};

export const getProducts = () => {
    return dispatch => {
        dispatch(getProductsStart());
        const userId = localStorage.getItem('userId');
        getData(
            "/user/" + userId + "/products",
            response => {
                dispatch(getProductsSuccess(response.data));
            },
            err => {
                dispatch(getProductsFail(err));
            });
    };
};