import {
    STRAIGHT_LINE_CERTIFICATION,
    STRAIGHT_LINE_CERTIFICATION_SUB_6,
    STRAIGHT_LINE_CERTIFICATION_SUBT,
    SUB_ANNUAL,
    SUB_ANNUAL_1_8900,
    SUB_ANNUAL_7_8900,
    SUB_MONTHLY,
    SUB_MONTHLY_1_1495,
    SUB_MONTHLY_7_1495,
    SUB_MONTHLY_7_995,
    SUB_QUARTERLY_1_3375,
    SUB_QUARTERLY_3_3375,
    SUB_QUARTERLY_7_2250,
    SUB_QUARTERLY_7_3375
} from "./products";

export const profileVersion = "1.7";

export const landingPageVideo = {
    name: "Landing Title Split Test",
    values: [

        {
            chance: 0,
            name: "Test Title",
            props: {
                videoUrl: "https://vimeo.com/330896545/b8e72e4110",
                landingTitle: "How To Convince Anyone To Say YES!"
            }
        },
        {
            chance: 1,
            name: "Control Title",
            props: {
                videoUrl: "https://vimeo.com/330896545/b8e72e4110",
                annualProduct: STRAIGHT_LINE_CERTIFICATION,
                monthlyProduct: STRAIGHT_LINE_CERTIFICATION_SUBT,
                quarterlyProduct: STRAIGHT_LINE_CERTIFICATION_SUB_6,
                landingTitle: "Straight Line Persuasion: 7 Simple Steps to Becoming a World-Class Closer"
            }
        },
        {
            chance: 0,
            name: "Old Title",
            props: {
                videoUrl: "https://vimeo.com/330896545/b8e72e4110",
                landingTitle: "The Foolproof System for Selling Anything to Anyone"

            }
        }
    ]
};

export const threeVsSeven = {
    name: "ThreeVsSeven",
    values: [
        {
            chance: 0,
            name: "3days1495",
            props: {
                days: 3,
                price: "14.95",
                quarterlyPrice: "33.75",
                annualPrice: "89",
                percentageOff: 50,
                percentageOffQuarterly: 25,
                videoUrl: "https://vimeo.com/user17087177/3dayfreetrial",
                monthlyProduct: SUB_MONTHLY,
                annualProduct: SUB_ANNUAL,
                quarterlyProduct: SUB_QUARTERLY_3_3375
            }
        },
        {
            chance: 0,
            name: "7days995",
            props: {
                days: 7,
                price: "9.95",
                quarterlyPrice: "22.50",
                annualPrice: "89",
                percentageOff: 25,
                videoUrl: "https://vimeo.com/user17087177/lpv02",
                monthlyProduct: SUB_MONTHLY_7_995,
                annualProduct: SUB_ANNUAL_7_8900,
                quarterlyProduct: SUB_QUARTERLY_7_2250
            }
        },
        {
            chance: 0,
            name: "7days1495",
            props: {
                days: 7,
                price: "14.95",
                quarterlyPrice: "33.75",
                annualPrice: "89",
                percentageOff: 50,
                percentageOffQuarterly: 25,
                videoUrl: "https://vimeo.com/327793957/343db6ce79",
                monthlyProduct: SUB_MONTHLY_7_1495,
                annualProduct: SUB_ANNUAL_7_8900,
                quarterlyProduct: SUB_QUARTERLY_7_3375
            }
        },
        {
            chance: 0,
            name: "1day1495",
            props: {
                days: 1,
                price: "14.95",
                quarterlyPrice: "33.75",
                annualPrice: "89",
                percentageOff: 50,
                percentageOffQuarterly: 25,
                videoUrl: "https://vimeo.com/user17087177/1daytrial",
                monthlyProduct: SUB_MONTHLY_1_1495,
                annualProduct: SUB_ANNUAL_1_8900,
                quarterlyProduct: SUB_QUARTERLY_1_3375
            }
        },
        {
            chance: 1,
            name: "3day1995",
            props: {
                days: 3,
                price: "19.95",
                quarterlyPrice: "42",
                annualPrice: "97",
                percentageOff: 60,
                percentageOffQuarterly: 30,
                videoUrl: "https://vimeo.com/user17087177/lp41519",
                monthlyProduct: STRAIGHT_LINE_CERTIFICATION_SUBT,
                annualProduct: STRAIGHT_LINE_CERTIFICATION,
                quarterlyProduct: STRAIGHT_LINE_CERTIFICATION_SUB_6
            }
        },
    ]
};

export const activeProfiles = [
    landingPageVideo,
    threeVsSeven
];

export const reportedProfiles = [
    landingPageVideo
];

