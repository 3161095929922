import React, {Component} from 'react';
import classes from './IconList.module.css';
import financial from '../../../assets/images/icons/financial.png';
import science from '../../../assets/images/icons/result.png';
import handshake from '../../../assets/images/icons/hand-shake.png';
import original from '../../../assets/images/icons/original.png';
import Buttons from "../ButtonGroup/Buttons";

class IconList extends Component {

    render() {

        return (
            <div className={classes.mainContainer}>

                <div className={classes.root}>

                    <div className={classes.iconSectionHeaderRow}>
                        <h1 className={classes.iconSectionHeader}>
                            WHY STRAIGHT LINE SALES CERTIFICATION
                        </h1>
                    </div>
                    <div className={classes.rowList}>

                        <p className={classes.text}>

                            <div className={classes.iconRow}>
                                <img className={classes.icon} src={financial} width={80} height={65} alt="freedom"/>

                                <h1 className={classes.header}>
                                    MORE MONEY = MORE FREEDOM</h1>
                            </div>
                            Raising your income level will not only provide you with financial security, but also allow
                            you to help those around you live more fulfilling lives.
                        </p>
                        <p className={classes.text}>
                            <div className={classes.iconRow}>
                                <img className={classes.icon} src={science} width={80} height={65} alt="freedom"/>

                                <h1 className={classes.header}>
                                    FIELD TESTED, FIELD PROVEN</h1>
                            </div>
                            SLSC is the culmination of more than 30 years of real-world experience in every industry
                            imaginable. As a result, we can take virtually any individual, from any background, and turn
                            them into a world-class closer and top producer in their field.
                        </p>


                    </div>

                    <div className={classes.rowList}>
                        <p className={classes.text}>
                            <div className={classes.iconRow}>
                                <img className={classes.icon} src={handshake} width={80} height={65} alt="freedom"/>

                                <h1 className={classes.header}>
                                    CONVERT ANYONE</h1>
                            </div>
                            This program will grant you the power to persuade almost anyone into doing virtually
                            anything. Of course, great power comes with great responsibility. We trust you will use your
                            newfound abilities ethically and with the utmost integrity.
                        </p>
                        <p className={classes.text}>
                            <div className={classes.iconRow}>
                                <img className={classes.icon} src={original} width={85} height={65} alt="freedom"/>

                                <h1 className={classes.header}>
                                    UNIQUE VALUE</h1>
                            </div>
                            This rare mentorship program will help you achieve the same kind of over-the-top results
                            that other sales courses always promise but can’t deliver. In fact, we’re so confident in
                            our ability to teach you that we’ll bet that you’ll start seeing results after the first few
                            weeks!
                        </p>


                    </div>
                    <div className={classes.ctaRow}>
                        <Buttons
                        buttonText={"Apply Now!"}
                        />
                    </div>


                </div>

            </div>
        )
    }


}

export default IconList;
