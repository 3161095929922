import React, {Component} from 'react';
import classes from "./Buttons.module.css";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import ReactPixel from "react-facebook-pixel";
import Popup from "reactjs-popup";
import {postContactToBackend} from "../../../shared/utility";
import HubspotForm from 'react-hubspot-form';

class Buttons extends Component {
    state = {
        name: '',
        email: '',
        phone: '',
        industry: '',
        levelOfExperience: '',
        currentMonthlyIncome: '',
        desiredMonthlyIncome: '',
        agreedCheckBox: false,
        formSuccess: false
    }

    componentDidMount() {
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init('677343869578804', null, options);
    }

    handleFormSubmit() {
        ReactPixel.track('Lead', {form: 'SLSC.pro'});
        //this.props.joinClicked();
    }

    handleLearnClick(e) {
        e.preventDefault()
        ReactPixel.trackCustom('FreeConsultation');
        // this.props.learnClicked();
    }

    submitForm() {

        /*   postDataHubspot(
               this.state,
               response => {
                   console.log("event sent hubspot ", response)
               },
               err => {
                   console.log("event sent hubspot FAIL ", err)
               },
           )*/
        postContactToBackend(
            "/hubspot/certification",
            this.state,
            response => {
                console.log("Form success, ", response)
                this.setState({
                    ...this.state,
                    formSuccess: true
                })
            },
            err => {
                console.log("Form err, ", err)
            }
        )
        console.log("Form submitted ", this.state)


    }

    handleNameChange(e) {
        this.setState({
            ...this.state,
            name: e.target.value
        })
    }

    handleEmailChange(e) {
        this.setState({
            ...this.state,
            email: e.target.value
        })
    }

    handlePhoneChange(e) {
        this.setState({
            ...this.state,
            phone: e.target.value
        })
    }

    handleIndustryChange(e) {
        this.setState({
            ...this.state,
            industry: e.target.value
        })
    }

    handleExperienceChange(e) {
        this.setState({
            ...this.state,
            levelOfExperience: e.target.value
        })
    }

    handleCurrentIncomeChange(e) {
        this.setState({
            ...this.state,
            currentMonthlyIncome: e.target.value
        })
    }

    handleDesiredIncomeChange(e) {
        this.setState({
            ...this.state,
            desiredMonthlyIncome: e.target.value
        })
    }

    handleCheckChange(e) {
        this.setState({
            ...this.state,
            agreedCheckBox: e.target.value
        })
    }

    render() {
        return (
            <table className={classes.buttons}>
                {/*       <button className={classes.hideButton + " " + classes.buttons} onClick={() => this.handleJoinClick()}>
                    <div className={classes.button}>Fill Out My Application Now!</div>
                </button>*/}

                <div className={classes.hubContainer}>
                    <Popup

                        arrow={false}
                        trigger={<button
                            className={classes.buttons}
                            onClick={this.props.onClickFunction ? this.props.onClickFunction : (e) => this.handleLearnClick(e)}
                        >
                            <div className={classes.button}>
                                {this.props.buttonText ? this.props.buttonText : "Speak to a Team Member"}
                            </div>

                        </button>} position="center center">



                        {!this.state.formSuccess ?
                            <div className={classes.hubspotBox}>
                                <HubspotForm
                                    portalId='8012363'
                                    formId='e790cf57-c0ce-49b7-917b-f6b19d013a01'
                                    onSubmit={() => this.handleFormSubmit()}
                                    onReady={(form) => console.log('Form ready!')}
                                    loading={<div>Loading...</div>}
                                />

                            </div>
                            :
                            <div>
                                <div className={classes.popupFormBox}>
                                    <div className={classes.thankYouBox}>
                                        <div className={classes.thankYouText}>Thank You!</div>
                                        <div className={classes.thankYouSubText}>A member of our team will be in touch
                                            with you shortly.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Popup>
                </div>

            </table>
        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        joinClicked: () => dispatch(actions.joinButtonClicked()),
        learnClicked: () => dispatch(actions.learnClicked())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);