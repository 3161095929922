import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import ContactReducer from './store/reducers/contact';
import ProductReducer from './store/reducers/product';
import StateReducer from './store/reducers/state';
import AnalyticsReducer from './store/reducers/analytics';
import SplitReducer from './store/reducers/split';
import BrainTreeReducer from './store/reducers/braintree';
import MixPanelReducer from './store/reducers/mixPanel';
import OrderFormReducer from './store/reducers/orderForm';
import CreditReducer from './store/reducers/credit';
import {StripeProvider} from 'react-stripe-elements';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    contact: ContactReducer,
    product: ProductReducer,
    pages: StateReducer,
    analytics: AnalyticsReducer,
    split: SplitReducer,
    braintree: BrainTreeReducer,
    mixPanel: MixPanelReducer,
    orderForm: OrderFormReducer,
    credit: CreditReducer


});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));
//TODO LIVEMODE: const stripeKey = process.env.NODE_ENV !== 'production' ? "pk_test_NWV4IxfTTJUfb8ed1aPvUZNm" : "pk_live_YZjIz5GCjTtmjQUxVsCC13Dt";
const stripeKey = process.env.NODE_ENV !== 'production' ? "pk_test_NWV4IxfTTJUfb8ed1aPvUZNm" : "pk_live_YZjIz5GCjTtmjQUxVsCC13Dt";
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <StripeProvider apiKey={stripeKey}>
                <App/>
            </StripeProvider>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
