import React, {Component} from 'react';
import classes from './ThankYou.module.css';
import Logo from "../Header/Logo";
import * as productTags from "../../shared/products";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {DOWNSELL, FAST_TRACK, MANUAL, SLP, THANK_YOU_PAGE} from "../../shared/events";
import VimeoPlayer from "../Vimeo/VimeoPlayer";
import Divider from "./ThankYouDivider/ThankYouDivider";
import ReactPixel from "react-facebook-pixel";

class ThankYou extends Component {

    state = {
        selectedProducts: [],
        brainTreeInstance: null,
        brainTreeReady: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getProducts();
        this.props.sendEvent(THANK_YOU_PAGE);
        window.gtag('event', 'conversion', {'send_to': 'AW-980750786/O92wCKiJpKYBEMKj1NMD'});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.productsSuccess && this.props.productsSuccess) {
            this.resetSelectedProducts();
            this.props.getProducts();
            this.props.invalidateToken();
        }
    }

    setBrainTreeInstance(instance) {
        instance.on('paymentMethodRequestable', (event) => {
            this.setState({
                ...this.state,
                brainTreeReady: true
            })
        });
        this.setState({
            ...this.state,
            brainTreeInstance: instance,
            brainTreeReady: instance.isPaymentMethodRequestable()
        })
    }

    submit() {
        const productEvents = {
            [productTags.FAST_TRACK]: FAST_TRACK,
            [productTags.SCRIPT_BUILDER_DOWNSELL]: DOWNSELL,
            [productTags.STRAIGHT_LINE_PERSUASION]: SLP,
            [productTags.SO_MANUAL]: MANUAL,
        };
        this.state.selectedProducts.forEach(p => this.props.sendEvent("Last Page " + productEvents[p]));
        if (this.state.brainTreeInstance) {
            this.state.brainTreeInstance.requestPaymentMethod((err, payload) => {
                if (err) {
                    // Do something with the error
                } else {
                    this.submitNonce(payload.nonce)
                }
            })
        } else {
            console.warn("Instance not loaded");
        }
    }

    submitNonce(nonce) {
        this.props.setNonceAndBuy(nonce, this.state.selectedProducts, this.props.transactionProperties)
    }

    toggleProduct(product) {
        let newProducts;
        if (this.state.selectedProducts.includes(product)) {
            newProducts = this.state.selectedProducts.filter(p => p !== product)
        } else {
            newProducts = this.state.selectedProducts.slice();
            newProducts.push(product)
        }
        this.setState({
            ...this.state,
            selectedProducts: newProducts
        })
    }

    resetSelectedProducts() {
        this.setState({
            ...this.state,
            selectedProducts: []
        })
    }

    render() {
        if (this.props.products) {
            this.props.products.forEach((products) => {
                ReactPixel.track('Purchase', {
                    content_name: `slscert, $${(products.price / 100)}`,
                    value: (products.price / 100).toFixed(2),
                    currency: 'usd'
                });
            });
        }

        return (

            <div className={classes.root}>
                <div className={classes.divider}>
                    <Divider
                        titleStart=""
                        spanOne="CONGRATULATIONS!"
                        spanTwo="Welcome to Straight Line Sales Certification!"
                        titleEnd=""
                        subTitle="You have taken the first step toward becoming a world-class closer and a top producer in your field. "

                    />
                </div>


                <div className={classes.subRoot}>


                    <div className={classes.video}>
                        <VimeoPlayer
                            width={660}
                            height={371}
                            threshold={800}
                            multiplier={0.90}
                            url="https://vimeo.com/339676317"/>
                    </div>
                    <p className={classes.mainInfo}>
                        As one of the first 1,000 people to enroll, you will be invited to attend a one-day, live
                        training and graduation event with me in Los Angeles!
                    </p>
                    <p className={classes.mainInfo}>

                        Following the live training, we will have an official graduation ceremony where I will
                        personally hand you your diploma, and we can snap a few photos together.
                    </p>
                    <p className={classes.mainInfo}>
                        It's going to be a great day, and I can't wait to see you then!
                    </p>

                    <h2 className={classes.importantTitle}>*So, what’s the next step?*</h2>
                    <p className={classes.importantContent}>
                        We’re aiming for a release date of no later than July 1st, at which point you will receive an
                        email with your
                        <b> personal login information</b> which will give you access to the <b>Introduction
                        Module</b> and <b>Lesson One
                    </b>.
                    </p>
                    <p className={classes.importantContent}>
                        Again, congratulations, and keep an eye on your inbox for your onboarding email. If you
                        have
                        any questions just contact customer service and they will be happy to help you.
                    </p>

                    <Logo height='70px'/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.product.products,
        productsSuccess: state.braintree.productsSuccess,
        loading: state.braintree.loading,
        error: state.braintree.error,
        transactionProperties: state.mixPanel.superProps
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: () => dispatch(actions.getProducts()),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setNonceAndBuy: (nonce, products, transactionProperties) => dispatch(actions.setNonceAndBuy(nonce, products), transactionProperties),
        invalidateToken: () => dispatch(actions.invalidateBrainTreeToken())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);