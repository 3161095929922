import React, {Component} from 'react';
import classes from './Mentor.module.css';
import books from '../../../assets/images/books.png';
import speaking from '../../../assets/images/motivation-speaking.jpg';
import sl from '../../../assets/images/SL.png';
import Divider from "../Divider/Divider";

class Mentor extends Component {
    render() {

        return (
            <div className={classes.mainContainer}>

                <div className={classes.root}>


                    <div className={classes.rowContainer}>

                        <div className={classes.columnContainer}>

                            <div className={classes.columnWrap}>


                                <h1 className={classes.headingText}>
                                    #1 SALES TRAINER
                                    IN THE WORLD </h1>


                                <div className={classes.description}>
                                    Jordan Belfort is a household name. He has carved out his own legacy in the form of
                                    creating more million-dollar producers than all other sales trainers combined. His
                                    famous Straight Line System has been used to train the most effective sales forces in
                                    the world. This program has been curated and refined over the last 30 years into a
                                    foolproof situation for turning virtually any individual into a world-class closer.
                                </div>
                            </div>
                        </div>

                        <img className={classes.images} src={sl} alt="Jb Books"/>



                    </div>
                </div>

                <div className={classes.rowContainer}>
                    <img className={classes.images} src={books} alt="Jb Books"/>
                    <div className={classes.columnContainer}>
                        <div className={classes.columnWrap}>
                            <h1 className={classes.headingText}>
                                BEST-SELLING AUTHOR

                            </h1>

                            <div className={classes.description}>
                                Jordan is a dynamic and powerful motivational speaker at an international level. He
                                has toured the world multiple times, performing in-person training events to
                                millions of ambitious and forward-thinking salespeople, executives, and
                                entrepreneurs, all of whom have benefited greatly from his unparalleled wisdom,
                                experience, and boundless energy.
                            </div>
                        </div>
                    </div>

                </div>


                <div className={classes.rowContainer}>

                    <div className={classes.columnContainer}>
                        <div className={classes.columnWrap}>
                            <h1 className={classes.headingText}>
                                WORLDWIDE
                                MOTIVATIONAL SPEAKER </h1>
                            <div className={classes.description}>
                                Jordan is a dynamic and powerful motivational speaker on an international scale. He has
                                toured the world multiple times performing live, in-person training events to millions
                                of ambitious and forward-thinking salespeople, executives, and entrepreneurs, all of
                                whom have benefitted greatly from his unparalleled wisdom, experience, and boundless
                                energy.
                            </div>
                        </div>
                    </div>
                    <img className={classes.images} src={speaking} alt="Jb Books"/>
                </div>



                <div className={classes.desktopDivider}>
                    <div className={classes.divider}>
                        <Divider
                            titleStart=" "
                            spanOne=""
                            titleEnd=""
                            subTitle='Become Certified In Straight Line Sales & Add A Zero Onto Your Income'
                            quotes=''

                        />

                    </div>
                </div>


                <div className={classes.mobileRoot}>

                    <div className={classes.divider}>
                        <Divider
                            spanOne="Meet Your Mentor"
                            subTitle='Jordan Belfort'
                            quotes=''

                        />

                    </div>

                    <div className={classes.mobileSubRoot}>

                        <div className={classes.mobileColumns}>
                            <h1 className={classes.headingText}> BEST SELLING AUTHOR</h1>
                        </div>

                        <div className={classes.mobileColumns}>
                            <div>
                                <img className={classes.images} src={books} alt="Jb Books"/>
                            </div>
                        </div>
                        <div className={classes.mobileColumns}>
                            <div className={classes.description}>
                                Jordan is a dynamic and powerful motivational speaker at an international level. He has
                                toured the world multiple times, performing in-person training events to millions of
                                ambitious and forward-thinking salespeople, executives, and entrepreneurs, all of whom
                                have benefited greatly from his unparalleled wisdom, experience, and boundless energy.
                            </div>
                        </div>


                        <div className={classes.mobileColumns}>
                            <h1 className={classes.headingText}> WORLDWIDE
                                MOTIVATIONAL SPEAKER</h1>
                        </div>

                        <div className={classes.mobileColumns}>
                            <div>
                                <img className={classes.images} src={speaking} alt="Jb Books"/>
                            </div>
                        </div>
                        <div className={classes.mobileColumns}>
                            <div className={classes.description}>
                                Jordan is a dynamic and powerful motivational speaker on an international scale. He has
                                toured the world multiple times performing live, in-person training events to millions
                                of ambitious and forward-thinking salespeople, executives, and entrepreneurs, all of
                                whom have benefitted greatly from his unparalleled wisdom, experience, and boundless
                                energy.
                            </div>
                        </div>


                        <div className={classes.mobileColumns}>
                            <h1 className={classes.headingText}>
                                #1 SALES TRAINER
                                IN THE WORLD </h1>
                        </div>

                        <div className={classes.mobileColumns}>
                            <div>
                                <img className={classes.images} src={sl} alt="Jb Books"/>
                            </div>
                        </div>
                        <div className={classes.mobileColumns}>
                            <div className={classes.description}>
                                Jordan Belfort is a household name. He has carved out his own legacy in the form of
                                creating more million-dollar producers than all other sales trainers combined. His
                                famous Straight Line System has been used to train the most effective sales forces in
                                the world. This program has been curated and refined over the last 30 years into a
                                foolproof situation for turning virtually any individual into a world-class closer.
                            </div>
                        </div>





                    </div>
                    <div className={classes.divider}>
                        <Divider
                            titleStart=" "
                            spanOne=""
                            titleEnd=""
                            subTitle='Become Certified In Straight Line Sales & Add A Zero Onto Your Income'
                            quotes=''

                        />

                    </div>

                </div>


            </div>


        )
    }
}

export default Mentor;