import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    products: []
};

const getProductsSuccess = ( state, action) => {
    return updateObject( state, {
        ...state,
        products: action.products
    })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_PRODUCTS_SUCCESS: return getProductsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;