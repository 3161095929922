import * as actionTypes from "./actionTypes";
import {postData} from "../../shared/utility";
import {addProduct} from "./product";

export const setCreditInfoStart = () => {
    return {
        type: actionTypes.SET_CREDIT_INFO_START
    };
};

export const setCreditInfoSuccess = () => {
    return {
        type: actionTypes.SET_CREDIT_INFO_SUCCESS,
    };
};

export const setCreditInfoFail = (error) => {
    return {
        type: actionTypes.SET_CREDIT_INFO_FAIL,
        error: error
    };
};

export const setPaymentType = (paymentType) => {
    return {
        type: actionTypes.SET_PAYMENT_TYPE,
        paymentType: paymentType
    };
};

export const setCreditInfo = (creditInfo, products) => {
    return dispatch => {
        dispatch(setCreditInfoStart());

        const data = {
            token: creditInfo.token,
            pap: creditInfo.pap,
            tag: creditInfo.tag,
            src: localStorage.getItem('src')
        };
        const userId = localStorage.getItem('userId');
        postData(
            "/credit/" + userId,
            data,
            response => {
                dispatch(setCreditInfoSuccess());
                products.forEach((tag) => dispatch(addProduct(tag)));
                window.sendConversion(response.data);
            },
            err => {
                dispatch(setCreditInfoFail(err.response.data.message));
            });
    };
};
