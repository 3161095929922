import React, { Component } from 'react';
import classes from "../CreditCardForm/CreditCardForm.module.css";
import {CardCVCElement, CardExpiryElement, CardNumberElement} from "react-stripe-elements";

class CreditForm extends Component {
    render() {
        return <div className={classes.creditFormBlock}>
            <div className={classes.rowBlock}>
                <CardNumberElement className={classes.input}/>
            </div>
            <div className={classes.rowBlock}>
                <CardExpiryElement className={classes.input}/>
                <CardCVCElement className={classes.input}/>
            </div>
        </div>;
    }
}

export default CreditForm;