import React from 'react';
import classes from './Divider.module.css';

export const properties = {
    props: {
        titleStart: "",
        spanOne: "",
        titleEnd: "",
        subTitle: "",
        quotes: ""

    }
};
const Divider = (props) => (


    <div className={classes.sectionDivider}>
        <div className={classes.sectionDividerTextBox}>
            <div className={classes.title}>
                {props.titleStart}
                <span className={classes.yellow}> {props.spanOne}</span>
                {props.titleEnd}
            </div>
            <div className={classes.container}>

                <div className={classes.subTitle}>
                    {props.subTitle}

                    <p className={classes.quotes}><i>{props.quotes}</i></p>

                </div>


            </div>


        </div>

    </div>


);
export default Divider;