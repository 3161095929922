import React, {Component} from 'react';
import classes from './ContactForm.module.css';
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import Spinner from "../../../Spinner/Spinner";
import {sendAnalytics} from "../../../../shared/utility";
import PhoneInput from "react-phone-number-input";
import {SUBMIT_OPTIN} from "../../../../shared/events";
import ReactPixel from "react-facebook-pixel";


class ContactForm extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        firstNameError: null,
        lastNameError: null,
        emailError: null,
        phoneError: null
    };


    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            this.setState({
                firstName: '',
                lastName: '',
                email: '',
                phone: ''
            });
        } else {
            this.setState({
                firstName: 'test',
                lastName: 'test',
                email: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + "@jbtesting.com",
                phone: '3238068293'
            });
        }
        this.props.sendEvent("contact-form");
        sendAnalytics("Funnel contact form page");
    }

    handleChangeFirstName(event) {
        let val = event.target.value;
        const error = ContactForm.validateFirstName(val);
        this.setState({
            ...this.state,
            firstName: val,
            firstNameError: error
        })
    }

    handleChangeLastName(event) {
        let val = event.target.value;
        const error = ContactForm.validateLastName(val);
        this.setState({
            ...this.state,
            lastName: val,
            lastNameError: error
        })
    }

    handleChangeEmail(event) {
        let val = event.target.value;
        const error = ContactForm.validateEmail(val);
        this.setState({
            ...this.state,
            email: val,
            emailError: error
        })
    }

    handleChangePhone(event) {
        let val = event.target.value;
        const error = ContactForm.validatePhone(val);
        this.setState({
            ...this.state,
            phone: val,
            phoneError: error
        })
    }

    static validateFirstName(val) {
        if (val.length === 0) {
            return 'First name is required'
        }
        return null
    }

    static validateLastName(val) {
        if (val.length === 0) {
            return 'Last name is required'
        }
        return null
    }

    static validateEmail(val) {
        if (val.length <= 0) {
            return 'email is required'
        }
        return null
    }

    static validatePhone(val) {
        if (val.length === 0) {
            return 'phone number is required'
        }
        return null;
    }

    submit() {
        const firstNameError = ContactForm.validateFirstName(this.state.firstName);
        const lastNameError = ContactForm.validateLastName(this.state.lastName);
        const emailError = ContactForm.validateEmail(this.state.email);
        const phoneError = ContactForm.validatePhone(this.state.phone);
        if (lastNameError != null || firstNameError != null || emailError != null || phoneError != null) {
            this.setState({
                ...this.state,
                firstNameError: firstNameError,
                lastNameError: lastNameError,
                emailError: emailError,
                phoneError: phoneError
            });
            return;
        }

        this.props.setContact({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            source: "Certification Funnel"
        });
        this.props.sendEvent(SUBMIT_OPTIN);
        window.fbq('trackCustom', 'Opt-In');
        ReactPixel.trackCustom('InitiateCheckout', {content_name: 'slscert'});
        sendAnalytics("Funnel contact form submit");
        window.sendOptin();
    }

    render() {
        if (this.props.loading) {
            return <div className={classes.root}>
                <div className={classes.spinnerBox}>
                    <Spinner/>
                </div>
            </div>
        }
        let country = localStorage.getItem("country");
        if (country === null) {
            country = "ZZ";
        }
        return (
            <div className={classes.root}>
                {this.props.error ? <p className={classes.formError}>{this.props.error}</p> : null}
                <input className={classes.input + (this.state.firstNameError ? ' ' + classes.inputError : '')}
                       type='text' value={this.state.firstName}
                       onChange={(event) => this.handleChangeFirstName(event)}
                       placeholder='First Name...'/>
                {this.state.firstNameError ? <p className={classes.error}>{this.state.firstNameError}</p> : null}
                <input className={classes.input + (this.state.lastNameError ? ' ' + classes.inputError : '')}
                       type='text' value={this.state.lastName}
                       onChange={(event) => this.handleChangeLastName(event)}
                       placeholder='Last Name...'/>
                {this.state.lastNameError ? <p className={classes.error}>{this.state.lastNameError}</p> : null}
                <input className={classes.input + (this.state.emailError ? ' ' + classes.inputError : '')}
                       type='email'
                       onChange={(event) => this.handleChangeEmail(event)}
                       placeholder='Email Address...'/>
                {this.state.emailError ? <p className={classes.error}>{this.state.emailError}</p> : null}
                <PhoneInput
                    placeholder="Phone Number..."
                    className={classes.input + (this.state.phoneError ? ' ' + classes.inputError : '')}
                    value={this.state.phone}
                    country={country}
                    onChange={phone => this.setState({phone})}/>
                {this.state.phoneError ? <p className={classes.error}>{this.state.phoneError}</p> : null}
                <button className={classes.submit + " " + classes.desktop} onClick={() => this.submit()}>Go To Step #2
                </button>
                <button className={classes.submit + " " + classes.phone} onClick={() => this.submit()}>Go To Step #2
                </button>
                <p className={classes.subtext}>We Respect Your Privacy & Information</p>
                <p className={classes.subtext}>By clicking submit I understand that my click is my electronic signature,
                    and authorize Global Motivation Inc to send recurring messages with information regarding the weekly
                    training, daily motivation, and any other program purchased via sms/text messaging to my mobile
                    phone. Text STOP to cancel and HELP for help. Msg & data rates may apply. I understand that my
                    consent is not a condition of using the service(s) provided on this website.</p></div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.contact.loading,
        error: state.contact.error,
        source: state.contact.source
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContact: (contact) => dispatch(actions.setContactInfo(contact)),
        sendEvent: (eventTag) => dispatch(actions.sendAnalytics(eventTag))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);