import React, {Component} from 'react';
import classes from "./VideoDivider.module.css";
import BackgroundVideo from "./BackgroundCoverVideoDivider";
import Video from "../../../assets/images/FunnelSizzle.mp4";

class VideoDivider extends Component {

    render() {

        return (
            <div className={classes.section}>
                <div className={classes.blackOverlay}>
                    <div className={classes.bgVid}>
                        <BackgroundVideo ref={p => this.player = p}
                                       containerWidth={100}
                                       containerHeight={100}
                                       src={Video}
                                       poster={''}
                                       startTime={10}
                                       autoPlay={true}
                                       muted={true}/>
                    </div>
                </div>
                <div>
                    <div className={classes.container}>
                        <div className={classes.subContainer}>

                        <div className={classes.headerText}>
                            Meet Your Mentor
                        </div>
                            <div className={classes.jb}>
                                JORDAN BELFORT
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default VideoDivider;