import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    loading: false,
    error: null,
    data: null,
    buySuccess: false
};

const getOrderFormStart = ( state, action) => {
    return updateObject( state, {
        loading: true,
        error: null,
        data: null
    })
};

const getOrderFormSuccess = ( state, action) => {
    return updateObject( state, {
        loading: false,
        error: null,
        data: action.data
    })
};

const getOrderFormFail = ( state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error,
        data: null
    })
};

const buyOrderFormStart = ( state, action) => {
    return updateObject( state, {
        loading: true,
        error: null,
        buySuccess: false
    })
};

const buyOrderFormSuccess = ( state, action) => {
    return updateObject( state, {
        loading: false,
        error: null,
        buySuccess: true
    })
};

const buyOrderFormFail = ( state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error,
        buySuccess: false
    })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ORDER_FORM_START: return getOrderFormStart(state, action);
        case actionTypes.GET_ORDER_FORM_SUCCESS: return getOrderFormSuccess(state, action);
        case actionTypes.GET_ORDER_FORM_FAIL: return getOrderFormFail(state, action);
        case actionTypes.BUY_ORDER_FORM_START: return buyOrderFormStart(state, action);
        case actionTypes.BUY_ORDER_FORM_SUCCESS: return buyOrderFormSuccess(state, action);
        case actionTypes.BUY_ORDER_FORM_FAIL: return buyOrderFormFail(state, action);
        default:
            return state;
    }
};

export default reducer;