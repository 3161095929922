import React, {Component} from 'react';
import classes from './Landing.module.css';

import TestComponent from "./WhoIs/TestComponent"
import AboveFold from "./AboveFold/AboveFold";
import Increase from "./Increase/Increase";
import Video from "../../assets/images/FunnleSizzle.mp4"
import IconList from "./IconList/IconList";
import VideoDivider from "./VideoDivider/VideoDivider";
import Mentor from "./Mentor/Mentor";
import Testimonial from "./Testimonial/Testimonial";
import ReactPixel from "react-facebook-pixel";
import {LANDING_PAGE} from "../../shared/events";
import * as queryString from "query-string";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import ModuleList from "./ModuleList/ModuleList";


class Landing extends Component {

    componentDidMount() {
        let query = this.props.location.search;
        const values = queryString.parse(query);
        let src = "Certification Funnel";
        if (values.src) {
            src = values.src
        }
        if (values.IkD8D1) {
            localStorage.setItem("smsId", values.IkD8D1)
        }
        if (values.sub) {
            localStorage.setItem("maId", values.sub);
            this.props.setAffiliateProps("Monster Ads", values.sub);
        }
        if (values.sub2) {
            localStorage.setItem("maClickId", values.sub2)
        }
        this.props.addSource(src);
        setTimeout(() => this.props.sendEvent(LANDING_PAGE), 1000);


        if (values.rdr && values.rdr === "form") {
            this.props.joinClicked()
        }
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };

        ReactPixel.init('677343869578804', null, options);
        ReactPixel.init('1185032095006553', null, options);
        ReactPixel.pageView();
        ReactPixel.trackCustom('SalesPage', null, options);
        if (values.rdr && values.rdr === "form") {
            this.props.joinClicked()
        }
    }

    handleJoinClick() {
        ReactPixel.track('AddToCart', {content_name: 'slscert'});
        this.props.joinClicked();
    }

    render() {

        return (

            <div className={classes.root}>
                <div className={classes.blackOverlay}>
                    <div className={classes.bgVid}>
                        <TestComponent ref={p => this.player = p}
                                       containerWidth={40}
                                       containerHeight={40}
                                       src={Video}
                                       poster={''}
                                       startTime={10}
                                       autoPlay={true}
                                       muted={true}/>
                    </div>
                </div>
         {/*       <div className={classes.mobileHeader}>
                    <HeaderMobile/>
                </div>*/}
                <div className={classes.mainBg}>
              {/*      <div className={classes.topHeaders}>
                        <Header/>
                        <Header2/>
                    </div>*/}

                    <AboveFold/>
                    <Increase/>
                    <ModuleList/>
                    {/*
                    <StaggeredList/>
*/}
                    <IconList/>

                    <div className={classes.vidOverlay}>
                        <VideoDivider/>

                    </div>
                    <Mentor/>
                    {/*
                    <Cta/>
*/}
                    <Testimonial/>


                    {/*
                    <GetStarted/>
*/}

                    {/*       <div className={classes.floatButtonBox} onClick={() => this.handleJoinClick()}>
                        <button className={classes.floatButton}>Apply Now!
                            <div className={classes.buttonSubtext}>
                            </div>
                        </button>
                    </div>
                    <div className={classes.floatPlaceholder}/>*/}
                </div>


            </div>


        )
    }


}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        joinClicked: () => dispatch(actions.joinButtonClicked()),
        addSource: (source) => dispatch(actions.setContactSource(source)),
        sendEvent: (eventTag) => {
            dispatch(actions.sendAnalytics(eventTag));
            dispatch(actions.sendEvent(eventTag))
        },
        setAffiliateProps: (system, id) => dispatch(actions.setAffiliateProps(system, id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);