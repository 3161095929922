import * as actionTypes from '../actions/actionTypes';
import {getBaseReferrer, getBaseSubId} from '../../shared/utility';
import {reportedProfiles} from "../../shared/splitProfiles";

const initialState = {
    superProps: {}
};

const setSplitProperties = (state, action) => {
    const reportedProfileNames = reportedProfiles.map(p => p.name);

    const splitValues = {};
    reportedProfileNames.forEach(pn => splitValues[pn] = action.splitChoices[pn]);

    window.mixpanel.register(splitValues);
    return {
        superProps: {
            ...state.superProps,
            ...splitValues
        }
    }
};

const setInitialProperties = (state, action) => {
    const baseReferrer = getBaseReferrer();
    window.mixpanel.register({
        "Source Name": "JB Certification Funnel",
        "Base Referrer": baseReferrer
    });
    return {
        superProps: {
            ...state.superProps,
            "Base Referrer": baseReferrer
        }
    }
};

const setAffiliateProperties = (state, action) => {
    window.mixpanel.register({
        "Affiliate Id": action.id,
        "Affiliate System": action.system
    });
    return {
        superProps: {
            ...state.superProps,
            "Affiliate Id": action.id,
            "Affiliate System": action.system
        }
    }
};

const setSubIdProperty = (state, action) => {
    const baseSubId = getBaseSubId(action.source);
    window.mixpanel.register({
        "Sub Id": action.source,
        "Base Sub Id": baseSubId
    });
    return {
        superProps: {
            ...state.superProps,
            "Sub Id": action.source,
            "Base Sub Id": baseSubId
        }
    }
};

const sendEvent = (state, action) => {
    if (process.env.NODE_ENV === 'production') {
        window.mixpanel.track(action.tag);
    } else {
        console.log("\"Sending\" analytics", action.tag)
    }
    return state;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SPLIT_TESTS_LOADED:
            return setSplitProperties(state, action);
        case actionTypes.AFFILIATE_LOADED:
            return setAffiliateProperties(state, action);
        case actionTypes.SET_CONTACT_SOURCE:
            return setSubIdProperty(state, action);
        case actionTypes.LOAD_PAGE:
            return setInitialProperties(state, action);
        case actionTypes.SEND_EVENT:
            return sendEvent(state, action);
        default:
            return state;
    }
};

export default reducer;