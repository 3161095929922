import React, {Component} from 'react';
import classes from "./LearnMore.module.css";
import {connect} from "react-redux";
import {STRAIGHT_LINE_PERSUASION} from "../../shared/products";
import * as actions from "../../store/actions";
import PageSpinner from "../Spinner/PageSpinner";
import ContactFormInquiry from "./ContactFormInquiry";
import {FaArrowLeft} from "react-icons/fa";

class LearnMore extends Component {

    state = {
        brainTreeInstance: null,
        brainTreeReady: false
    };


    render() {
        if (this.props.products.map(p => p.tag).includes(STRAIGHT_LINE_PERSUASION)) {
            return (
                <div className={classes.root}>
                    <h1>Thank you for your purchase!</h1>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <button className={classes.backButton} onClick={() => this.props.goToHomePage()}>
                    <FaArrowLeft className={classes.backButtonIcon}/>
                    Back to Main Page
                </button>
                {this.props.loading ? <PageSpinner/> : null}
                {this.props.error ? <div className={classes.error}>{this.props.error}</div> : null}
                <div className={classes.textContainer}>
                    {this.props.contactSuccess ? null :
                        <h1 className={classes.title}>Learn More About <b>Straight Line Sales Certification</b></h1>
                    }
                    <h2 className={classes.subTitle}> You will be contacted by a member of our sales team for a free
                        consultation.</h2>
                </div>
                <div className={classes.subRoot}>
                    {this.props.contactSuccess ?
                        <div>
                            <h1 className={classes.thanks}> Thank you for your inquiry!</h1>

                            <button className={classes.buttonYes}
                                    onClick={() => this.props.goToHomePage()}>
                                Back to Main Page
                            </button>
                        </div> :
                        <ContactFormInquiry allowExists/>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contactSuccess: state.pages.contactDone,
        slpDone: state.pages.slpDone,
        loading: state.braintree.loading,
        error: state.braintree.error,
        products: state.product.products
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToHomePage: () => dispatch(actions.goToHomePage(), window.scrollTo(0, 0))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnMore);