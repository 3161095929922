import React, {Component} from 'react';
import classes from './Testimonial.module.css';
import Carousel from "../../Carousel/Carousel";
import Buttons from "../ButtonGroup/Buttons";

class Testimonial extends Component {

    render() {

        return (
            <div className={classes.root}>
                <div className={classes.subRoot}>
                    <div className={classes.title}>
                        What People Are Saying About

                    </div>

                    <div className={classes.subTitle}>
                        Jordan Belfort's Straight Line Training

                    </div>

                    <div>
                        <Carousel
                            data={[
                                {type: 'video', url: "https://player.vimeo.com/video/301707630"}
                            ]}
                            width={900}
                            height={500}
                        />
                    </div>



                {/*    <div className={classes.leo}>
                        <img src={leo} className={classes.image} alt={"leo"}/>
                    </div>*/}
                    <div className={classes.members}>
                        <div className={classes.membersText}>
                            <div className={classes.imageGroup}>
                                <div className={classes.video}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={440}
                                            height={215}
                                            src={"https://player.vimeo.com/video/339639532"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.mobileVideo}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={280}
                                            height={275}
                                            src={"https://player.vimeo.com/video/339639532"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.names}>Daryl & Tonya Simon</div>
                                <div className={classes.positions}> </div>


                            </div>

                            <div className={classes.imageGroup}>
                                <div className={classes.video}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={440}
                                            height={215}
                                            src={"https://player.vimeo.com/video/339639717"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.mobileVideo}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={280}
                                            height={275}
                                            src={"https://player.vimeo.com/video/339639717"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.names}>Sal</div>
                                <div className={classes.positions}> </div>

                            </div>


                            <div className={classes.imageGroup}>
                                <div className={classes.video}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={440}
                                            height={215}
                                            src={"https://player.vimeo.com/video/337919770"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.mobileVideo}>
                                    <iframe title={"Jb Video"} className={classes.frame} width={280}
                                            height={275}
                                            src={"https://player.vimeo.com/video/337919770"} frameBorder="0"
                                            allowFullScreen/>
                                </div>
                                <div className={classes.names}>Frank Kern</div>
                                <div className={classes.positions}> </div>

                            </div>

                        </div>
                    </div>
                    <div className={classes.buttons}>
                        <Buttons
                        buttonText={"Apply Now!"}
                        />
                    </div>
                </div>


            </div>
        )
    }
}

export default Testimonial;